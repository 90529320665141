import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { ResizableTable } from "../../../commons/resizabletable/ResizableTable";
import i18n from "../../../../utilities/i18n";
import { currencyFormat } from "../../../../utilities/commonUtilities";
import { useDispatch } from "react-redux";
import { handleProcedureEditWindowClose, handleUpdateTableData, hideModalWindow, openClaimModalWindow, removeClaimFromTable, showNotification } from "../StateManagement/paymentSlice";
import CustomizedDialogs from "../../../modalWindowComponent/CustomizedDialogs";
import ProcedureList from "./ProcedureList";
import CustomizedSmallDialogs from "../../../modalWindowComponent/CustomisedSmallDialog";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingContext from "../../../../container/loadingContext";
import { getStorage } from "../../../../utilities/browserStorage";
import service from '../../../ClaimsModule/SearchClaims/service';
import { MAX_OPEN_TABS_CLAIMS, ROUTE_SEARCH_CLAIMS } from "../../../../utilities/staticConfigs";
const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    fontFamily: 'Arial, sans-serif'
};

const spinnerStyle = {
    border: '16px solid #f3f3f3',
    borderTop: '16px solid #3498db',
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    animation: 'spin 2s linear infinite'
};

const textStyle = {
    fontSize: '20px',
    fontFamily: 'lato',
    marginTop: '5px'
};

const tableHeadings = [
    i18n.t('payments.eraPage.table2.claimId'),
    i18n.t('payments.eraPage.table2.patientId'),
    i18n.t('payments.eraPage.table2.charges'),
    i18n.t('payments.eraPage.table2.opening_balance'),
    i18n.t('payments.eraPage.table2.allowedAmount'),
    i18n.t('payments.eraPage.table2.paidAmount'),
    i18n.t('payments.eraPage.table2.adjustment_1_amount'),
    i18n.t('payments.eraPage.table2.adjustment_2_amount'),
    i18n.t('payments.eraPage.table2.closingBalance'),
    "Receipts",
    "Actions"
];

function getRowClassName(index) {
    let classNames = '';
    if (index % 2 === 0) {
        classNames += 'procedureEditedTableRow ';
    } else {
        classNames += 'row-bg-change-last-opened-indication ';
    }
    return classNames.trim();
}


const InsuranceClaimList = (props) => {
    const dispatch = useDispatch();
    const { claimList, openClaimWindow, claimIdOpened, dataLoading, validationErrorModal, validationError,
        saveProcedureLoading
    } = useSelector((state) => state.paymentData);
    const setShowLoadingBar = useContext(LoadingContext);
    const handleProcedureEditSubmit = () => {
        dispatch(handleUpdateTableData())
    }

    const onAlertOk = () => {
        dispatch(hideModalWindow({ key: "validationErrorModal", open: false }))
    }


    useEffect(() => {
        setShowLoadingBar(saveProcedureLoading)
    }, [saveProcedureLoading])

    function claimRedirection(e, item) {
        let openedPKs = false;
        const result = service.GetSelectedTabs("claims", getStorage("practice"));
        result.then((response) => {
            openedPKs =
                response.data && response.data.opened_tab_pks
                    ? response.data.opened_tab_pks
                    : [];
            let allowedPk = ["search", "last10"];
            allowedPk.forEach((items) => {
                let index = openedPKs.indexOf(items);
                if (index !== -1) {
                    openedPKs.splice(index, 1);
                }
            });
            if (
                openedPKs &&
                Array.isArray(openedPKs) &&
                openedPKs.length >= MAX_OPEN_TABS_CLAIMS &&
                !openedPKs.includes(item.claim_id)
            ) {
                dispatch(showNotification({ message: i18n.t("errorMessages.max_claim_tabs"), tag: "error" }))
            } else {
                let redirect_item = {
                    pk: item.claim_id,
                    claim_id: item.custom_claim_id,
                    type: "claims",
                    action: "add",
                    practice_pk: getStorage("practice"),
                };
                const result1 = service.AddRemoveSelectedTab(redirect_item);
                result1.then((response1) => {
                    if (response1.status === 200 || response1.data === 201)
                        window.open(ROUTE_SEARCH_CLAIMS, "_blank");
                                        });
            }
        });
    }



    return (
        <div
            id='tableDiv'
            style={{
                overflowX: "auto",
                width: "100%",
                maxWidth: "auto",
                maxHeight: "auto",
            }}
        >
            {dataLoading ?
                <div>
                    <div style={loaderStyle}>
                        <div style={spinnerStyle}></div>
                        <span style={textStyle}>Loading data, please wait...</span>
                    </div>
                </div>
                :
                <ResizableTable
                    className="table-fixed_head-zindex"
                    id="overflow_auto"
                    tableHeadings={
                        <tr style={{ border: '1px solid #dee2e6', zIndex: 6 }}>
                            {
                                tableHeadings.map((item, index) => {
                                    return (
                                        <th style={{ zIndex: 6 }} key={index}>{item}</th>
                                    )
                                })}
                        </tr>
                    }
                    tableRows={
                        Object.keys(claimList).map((ele, index) => {
                            let item = claimList[ele];
                            let td_class = '';
                            td_class = 'cursor-pointer'
                            return (
                                <tr key={index} className={getRowClassName(index)}>
                                    <td align='center' className={td_class} >
                                        <Link
                                            to="#"
                                            className="advancedSearch"
                                            onClick={(e) => claimRedirection(e, item)}
                                        >
                                            {item.custom_claim_id}
                                        </Link></td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}> {item.custom_patient_id ? item.custom_patient_id : item.patient_id ? item.patient_id : ""} </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalCharge, item.totalChargeEdited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalBalance, item.totalBalanceEdited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalAllowedAmount, item.totalAllowedAmountEdited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalPaidAmount, item.totalPaidAmountEdited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalAdjustment1, item.totalAdjustment1Edited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalAdjustment2, item.totalAdjustment2Edited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalClosingBalance, item.totalClosingBalanceEdited)}
                                    </td>
                                    <td align='center' className={td_class} onClick={() => dispatch(openClaimModalWindow(item))}>
                                        {showtableData(item?.edited ? true : false, item.totalReceiptAmount, item.totalReceiptEdited)}
                                    </td>
                                    <td align='center' className={td_class}>
                                        <div style={{ display: "flex", justifyContent: 'center' }}>
                                            <IconButton aria-label="delete" size="large" style={{ marginRight: 8 }} onClick={() => dispatch(openClaimModalWindow(item))}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="large" onClick={() => dispatch(removeClaimFromTable(item.custom_claim_id))}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                />}
            <CustomizedDialogs
                modalId={'claimDetailModal'} submit={false} modalBodyId={'claimDetailBody'} type={"save"}
                showModal={openClaimWindow} header={i18n.t("payments.eraPage.claimDetails") + `(${claimIdOpened})`}
                setShowModalWindow={() => dispatch(handleProcedureEditWindowClose())} onSaveData={() => handleProcedureEditSubmit()}
            >
                <ProcedureList updatePayment={props.updatePayment} />
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={validationErrorModal}
                header={i18n.t("commons.alert")}
                alertOK={onAlertOk}
                type="alert"
                setShowModalWindow={() => dispatch(hideModalWindow({ key: "validationErrorModal", open: false }))}
            >
                <div className="display-grid">
                    {validationError.map((item, index) => {
                        return <span key={item.error} style={index == 0 ? {} : { marginTop: '10px' }}>{index + 1}{". "}{item.error}</span>;
                    })}
                </div>
            </CustomizedSmallDialogs>
        </div>
    )
}

export default InsuranceClaimList;


const showtableData = (isEdited, value, changedValue) => {
    return (
        <>
            {isEdited ?
                <>
                    <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                        {currencyFormat(value)}
                    </span>
                    {' '}
                    <span>
                        {currencyFormat(changedValue)}
                    </span>
                </>
                :
                currencyFormat(value)
            }
        </>
    )
}