import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'; // Accordion,Card,
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHistory, Link,useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import './style.css';
import service from './service';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { DEFAULT_COUNTRY, TAB1, TAB2, TIN_TYPES, ROUTE_PRACTICES, ROUTE_DASHBOARD, TAB3 } from '../../../utilities/staticConfigs';
import { PROVIDER_TYPE_ORGANIZATION } from "../../../utilities/dictionaryConstants";
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import { setStorage, getStorage } from '../../../utilities/browserStorage';

import PracticeLocations from "./practiceLocations";

import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input';
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch';
import SelectInput from '../../commons/input/SelectInput';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import EmailInput from '../../commons/EmailInput/EmailInput';
import NPISearch from '../../commons/NPISearch/NPISearch';
import { CityInput } from '../../commons/input/CityInput';
import ImageCrop from '../../commons/image-cropper/ImageCrop';
import ProfileCard from '../../commons/ProfileCard';
import CommonButton from '../../commons/Buttons';

import Multiselect from '../../../multiselect/src/index'; // NEW COMPONENT FOR SEARCHABLE SINGLE/MULTI SELECT https://github.com/rowyio/multiselect
import { CommonTinFormat, HandlingTIN, ValidateEmail, checkPermission, extractPermissions } from '../../../utilities/commonUtilities';
import { imageExtension } from '../../../utilities/validations';
import { US_STATES } from '../../../utilities/usStates';
import { customer_admin_privilege, permission_key_values_accountsetup } from '../../../utilities/permissions';
import profileImage from '../../../assets/images/profile-image.png';

import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import PracticeStatementConfig from './PracticeStatementConfig';

let arrInvalidPracticeAddrStr = ['po box', 'p o box', 'p.o. box', 'p.o box', 'po. box', 'p o. box', 'p obox', 'pobox'];

const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;

const PracticesAdd = (props) => {
    const selectedPracticePK = getStorage("practice");
    const [practicePK, setPracticePK] = useState('');
    const [activeTab, setActiveTab] = useState(TAB1);
    const [practiceID, setPracticeID] = useState('');
    const [practiceName, setPracticeName] = useState('');
    const [practiceType, setPracticeType] = useState('');
    const [defServiceLoc, setDefServiceLoc] = useState('');
    const [NPI, setNPI] = useState('');
    const [mSelTaxonomyList, setMSelTaxonomyList] = useState([]);
    const [taxonomy, setTaxonomy] = useState('');
    const [CLIA, setCLIA] = useState('');
    const [defaultClia,setDefaultClia]=useState('')
    const [imageUrl, setImageUrl] = useState(profileImage);

    const [imgUrl, setImgUrl] = useState(null);
    const [showImageCropperModalWindow, setShowImageCropperModalWindow] = useState(false);
    const [imageName, setImageName] = useState('');
    const isLogoValid = false;
    const [practiceLogo, setPracticeLogo] = useState('');
    const [TIN, setTIN] = useState('');
    const [TINType, setTINType] = useState('');
    const [bcbsProviderID, setBcbsProviderID] = useState('');
    const [tricareProviderID, setTricareProviderID] = useState('');
    const [uhcProviderID, setUhcProviderID] = useState('');
    const [commercialProviderID, setCommercialProviderID] = useState('');
    const [ispracticeLogoChanged, setIspracticeLogoChanged] = useState(false);
    const [practiceTypesList, setPracticeTypesList] = useState([]);
    const [defaultLocationList, setDefaultLocationList] = useState([]);

    //START ==> MODAL WINDOW PROPERTIES 
    const [showModalWindow, setShowModalWindow] = useState(false);

    //START ==> ADDRESS PROPERTIED
    const [streetOne, setStreetOne] = useState("");
    const [streetTwo, setStreetTwo] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [zip, setZip] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");

    const [payToStreetOne, setPayToStreetOne] = useState("");
    const [payToStreetTwo, setPayToStreetTwo] = useState("");
    const [payToCity, setPayToCity] = useState("");
    const [payToState, setPayToState] = useState("");
    const [payToCountry, setPayToCountry] = useState("");
    const [payToZip, setPayToZip] = useState("");
    const [payToPhone, setPayToPhone] = useState("");
    const [payToFax, setPayToFax] = useState("");
    const [payToEmail, setPayToEmail] = useState("");
    const [chkInactive, setChkInactive] = useState(false);
    const history = useHistory();
    const location=useLocation();



    const [copyAddress, setCopyAddress] = useState(false);

    const [form_error, setFormError] = useState({ 'practiceName': '', 'practiceType': '', 'defServiceLoc': '', 'NPI': '', 'taxonomy': '', 'CLIA': '', 'practiceLogo': '', 'TIN': '', 'TINType': '', 'searchNPIName': '', 'NPISearchList': '', 'streetOne': '', 'streetTwo': '', 'city': '', 'state': '', 'zip': '', 'phone': '', 'payToStreetOne': '', 'payToCity': '', 'payToState': '', 'payToZip': '', 'payToPhone': '' });

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [gotoList, setGotoList] = useState(false);
    const [inactivePracticeModal, setInactivePracticeModal] = useState(false);

    useEffect(() => {
        getPracticeTypesData();
        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            setPracticePK(props.location.state.selectedID);
        }
    }, []);

    useEffect(() => {
        if (practicePK) {
            getPracticeData(practicePK);
            getPracticeLocationData(practicePK);
        }
    }, [practicePK]);

    function onHide() {
        setShowModalWindow(false);
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    function getPracticeTypesData() {
        setShowLoadingBar(true);
        const result = service.ListPracticeTypes();
        result.then(response => {
            setShowLoadingBar(false);
            setPracticeTypesList(response.data);
        });
    }

    function getPracticeLocationData(practice_id) {
        if (practice_id) {
            setShowLoadingBar(true);
            const result = service.ListPracticeLocations(20, 0, practice_id);
            result.then(response => {
                setShowLoadingBar(false);
                setDefaultLocationList(response.data);
            });
        }
        else {
            setDefaultLocationList([]);
        }
    }

    function getPracticeData(practice_pk) {
        const result = service.GetPractice(practice_pk);
        result.then((response) => {
            setPracticeName(response.data.name);
            setPracticeType(response.data.practice_type);
            setPracticeID(response.data.custom_practice_id);
            setCLIA(response.data.clia);
            setDefaultClia(response.data.clia)
            setNPI(response.data.npi);
            setTaxonomy(response.data.taxonomy);
            setTIN(CommonTinFormat(response.data.tin));
            setTINType(response.data.tin_type.toString());
            setBcbsProviderID(response.data.bcbs_provider_id);
            setTricareProviderID(response.data.tricare_provider_id);
            setUhcProviderID(response.data.uhc_provider_id);
            setCommercialProviderID(response.data.commercial_provider_id);
            if (response.data.default_service_location_details)
                setDefServiceLoc(response.data.default_service_location_details.id);
            if (response.data.practice_location) {
                setStreetOne(response.data.practice_location_data.street1);
                setStreetTwo(response.data.practice_location_data.street2);
                setCity(response.data.practice_location_data.city);
                setState(response.data.practice_location_data.state);
                setZip(response.data.practice_location_data.zip_code);
                setCountry(response.data.practice_location_data.country);
            }
            setPhone(response.data.phone);
            setFax(response.data.fax);
            setEmail(response.data.email);
            setCopyAddress(response.data.pay_address_same);
            if (response.data.pay_location) {
                setPayToStreetOne(response.data.pay_location_data.street1);
                setPayToStreetTwo(response.data.pay_location_data.street2);
                setPayToCity(response.data.pay_location_data.city);
                setPayToState(response.data.pay_location_data.state);
                setPayToZip(response.data.pay_location_data.zip_code);
                setPayToCountry(response.data.pay_location_data.country);
            }
            setPayToPhone(response.data.pay_phone);
            setPayToFax(response.data.pay_fax);
            setPayToEmail(response.data.pay_email);
            setChkInactive(!response.data.active);
            if (response.data.practice_logo_url)
                setImageUrl(response.data.practice_logo_url)
        });
    }

    function onTabChange(e, tab) {
        setActiveTab(tab);
    }

    const handleMultiselect = (value) => {
        if (value) {
            setTaxonomy(value);
        }
        else {
            setTaxonomy('');
        }
    };

    const nameToSetterMap = {
        'practiceName': setPracticeName,
        'practiceType': setPracticeType,
        'defServiceLoc': setDefServiceLoc,
        'NPI': setNPI,
        'CLIA': setCLIA,
        'TIN': (trimmedValue) => setTIN(() => HandlingTIN(trimmedValue, TIN)),
        'TINType': setTINType,
        'bcbsProviderID': setBcbsProviderID,
        'tricareProviderID': setTricareProviderID,
        'uhcProviderID': setUhcProviderID,
        'commercialProviderID': setCommercialProviderID,
        'streetOne': setStreetOne,
        'streetTwo': setStreetTwo,
        'city': setCity,
        'state': setState,
        'country': setCountry,
        'zip': setZip,
        'phone': setPhone,
        'fax': setFax,
        'email': setEmail,
        'payToStreetOne': setPayToStreetOne,
        'payToStreetTwo': setPayToStreetTwo,
        'payToCity': setPayToCity,
        'payToState': setPayToState,
        'payToCountry': setPayToCountry,
        'payToZip': setPayToZip,
        'payToPhone': setPayToPhone,
        'payToFax': setPayToFax,
        'payToEmail': setPayToEmail
    };

    function onHandleChange(e) {
        if (e.target) {
            const name = e.target.name;
            let value = e.target.value;
            let trimmedValue = '';
            let arrFieldNames = ['practiceName', 'practiceType', 'CLIA', 'defServiceLoc', 'NPI', 'taxonomy', 'TIN', 'TINType',
                'searchNPIName', 'streetOne', 'streetTwo', 'city', 'state', 'country', 'zip', 'phone', 'fax', 'email', 'payToStreetOne',
                'payToStreetTwo', 'payToCity', 'payToState', 'payToCountry', 'payToFax', 'payToEmail', 'file_practiceLogo',
                'payToZip', 'payToPhone', 'bcbsProviderID', 'tricareProviderID', 'uhcProviderID', 'commercialProviderID'];

            const validNames = ['practiceName', 'CLIA', 'TIN', 'bcbsProviderID', 'tricareProviderID', 'uhcProviderID',
                'commercialProviderID','city', 'payToCity', 'payToCountry'
            ];

            if (validNames.includes(name)) {
                value = value.replace(/[^A-Za-z0-9.\s]/g, '');
            }

            if (arrFieldNames.includes(name)) {
                trimmedValue = value;
            }

            if (!trimmedValue && arrFieldNames.includes(name)) {
                setFormError({
                    ...form_error,
                    [name]: 'error'
                });
            } else {
                setFormError({
                    ...form_error,
                    [name]: ''
                });
            }

            // When user toggles the TIN Type (SSN || EIN) then reset the TIN Value
            if (name === 'TINType' && value !== TINType) {
                setTIN('')
            }

            const setter = nameToSetterMap[name];
            if (setter) {
                setter(trimmedValue);
                if (name === 'defServiceLoc') {
                  const cliaNumber =  defaultLocationList.find(item=>item.id == e.target.value).clia;

                  if (cliaNumber == null || cliaNumber == '') {
                    setCLIA(defaultClia); 
                  } else {
                    setCLIA(cliaNumber); 
                  }
                }

                if (name === 'practiceName' && !trimmedValue) {
                    setNPI('');
                }
            }

            getImage(name, e)
        }
    }

    //get image logo and other image formats

    const getImage = (name, e) => {
        if (name === 'file_practiceLogo') {
            const Imgname = e.target.name;
            let img_url = '';
            let img_obj = null;
            if (imageExtension(e.target.files[0].name)) {
                img_obj = e.target.files[0]
                img_url = URL.createObjectURL(e.target.files[0]);
            }
            setImgUrl(img_url);
            setImageName(img_obj.name);
            document.getElementById(Imgname).value = '';
            setShowImageCropperModalWindow(true);
        }
    }

    function changeActive(e) {
        if (e.target.checked) setChkInactive(true);
        else setChkInactive(false);
    }


    function onCopyAddress(e) {
        if (e.target.checked) {
            setCopyAddress(true);
            setPayToStreetOne(streetOne);
            setPayToStreetTwo(streetTwo);
            setPayToCity(city);
            setPayToState(state);
            setPayToCountry(country);
            setPayToZip(zip);
            setPayToPhone(phone);
            setPayToFax(fax);
            setPayToEmail(email);
            setFormError({
                ...form_error,
                ['payToStreetOne']: '',
                ['payToCity']: '',
                ['payToState']: '',
                ['payToZip']: '',
                ['payToPhone']: ''
            });
        }
        else if (!e.target.checked) {
            setCopyAddress(false);
            setPayToStreetOne("");
            setPayToStreetTwo("");
            setPayToCity("");
            setPayToState("");
            setPayToCountry("");
            setPayToZip("");
            setPayToPhone("");
            setPayToFax("");
            setPayToEmail("");
        }
    }

    function showSearchNPI() {
        setShowModalWindow(true);
    }

    //on npi search location and other details related to npi will set automatically
    function setSelectedNPI(npiData) {
        const {
            NPCountry,
            NPIAddress1,
            NPIAddress2,
            NPICity,
            NPIEmail,
            NPINum,
            NPIOrgName,
            NPIPhone,
            NPIPostalCode,
            NPIState
        } = npiData;

        if (NPINum && NPINum.length > 0) setNPI(NPINum);

        if (NPIOrgName && NPIOrgName.length > 0) setPracticeName(NPIOrgName);

        if (NPIAddress1 && NPIAddress1.length > 0) setStreetOne(NPIAddress1);

        if (NPIAddress2 && NPIAddress2.length > 0) setStreetTwo(NPIAddress2);

        if (NPICity && NPICity.length > 0) setCity(NPICity);

        if (NPIState && NPIState.length > 0) setState(NPIState);

        if (NPCountry && NPCountry.length > 0) setCountry(NPCountry);

        if (NPIPostalCode && NPIPostalCode.length > 0) {
            setZip(NPIPostalCode);
        }

        if (NPIPhone && NPIPhone.length > 0) {
            setPhone(NPIPhone);
        }

        if (NPIEmail && NPIEmail.length > 0) {
            setEmail(NPIEmail);
        }

        setShowModalWindow(false);
    }

    async function onSavePractice(e) {
        e.preventDefault();
        if (phone && phone.length < 11 ) {
            return;
        }
        if (hasError()) {
            setInactivePracticeModal(false);
            return;
        }
        setShowLoadingBar(true);
        let result = null;
        const data = createFormData();
        if (practicePK) {
            result = service.UpdatePractice(practicePK, data);
        } else {
            result = service.AddPractice(data);
        }
        result.then(async (response) => {
           
            if (response.status === 201 || response.status === 200) {
                if (response.status === 201) {
                    if (response.data.default_practice) {
                        setStorage("practice", response.data.default_practice);
                        setStorage("practiceName", response.data.default_practice_name);

                        // If this is a the first practice a new customer creates then after the practice is created, then need to fetch..
                        // the practice permission and the practice menu items from the api and store it in the local storage
                        const permissionCodeSet = localStorage.getItem('permissionCodeSet');

                        if (permissionCodeSet) {
                            const parsedPermissionCodeSet = JSON.parse(permissionCodeSet);
                            // When customer loges in for the first time that customer user will have only one permission
                            if (Object.keys(parsedPermissionCodeSet).length === 1) {
                                showNotifyWindow('show', 'success', i18n.t("userPages.practices.createPracticeInProgressIndicationMessage"));
                                await getPermissionsAndMenuForFirstPractice(response?.data?.id);
                             } 
                               
                        }

                      
                    }
                    showNotifyWindow('show', 'success', ADD_SUCCESS);
                    setPracticePK(response.data.id);
                }
               
                else
                    showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                setTimeout(() => {
                    setShowLoadingBar(false);
                    setGotoList(true);
                }, 1000)
            
        }
            else if (response.status=== 400 && response.data.message !== undefined) {
                            
                showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                
                setTimeout(() => {
                  setShowLoadingBar(false);
                }, 1000);
               
              } 
            else {
                showNotifyWindow('show', 'error', ADD_ERROR);
                setShowLoadingBar(false);
                setGotoList(false);
            }
        });
    }

    const getPermissionsAndMenuForFirstPractice = async (practicePk) => {
        // Utilizing Practice Switch api to get the permission and menu items for currently created first practice
        const practiceSideBarMenuAndPermissions = await service.GetPracticeMenuAndPermissionsOnPracticeSwitch(practicePk);
        const practiceModuleMenuSet = [];

        practiceSideBarMenuAndPermissions?.data?.practice_role_permissions?.forEach((item) => {
            // When the module item is of practice module
            if (item.is_super_admin_module === false) {
                // get the sub module lists for sidebar
                const subModules = item.sub_module?.map((subItem) => {
                    if (subItem?.system_generated === false) {
                        return {
                            ...subItem.ui_integration_data
                        }
                    } else {
                        return;
                    }
                });

                // Pushing the module and its sub item to practice module menu along with a dummy sub item
                // that is needed for utility 
                practiceModuleMenuSet.push({
                    ...item.ui_integration_data,
                    sub_menu:
                        subModules?.length ?
                            subModules : []
                });
            }
        });

        // This code sets the menu's route to a sub-menu item's route if no matching route is found in sub-menu,
        //  offering a default route based on sub-menu content when conditions allow.
        practiceModuleMenuSet.forEach((menu, index) => {
            if (menu.sub_menu?.length && menu.route !== '#') {
                const defaultRoute = menu?.route;
                const defaultSubMenuAvailable = menu?.sub_menu?.findIndex((item) => item?.route === defaultRoute);

                if (defaultSubMenuAvailable === -1) {
                    const defaultSubMenuToSet = menu?.sub_menu?.find((item) => item?.route !== '#')?.route ?? menu?.route;

                    if (defaultSubMenuToSet) {
                        practiceModuleMenuSet[index].route = defaultSubMenuToSet;
                    }
                }
            }
        });

        practiceModuleMenuSet.forEach((item, index) => {
            if (!item?.sub_menu[0]) {
                practiceModuleMenuSet[index].sub_menu = []
            }
        })
        // Store the permission and menu items in the local storage
        setStorage('practiceModuleMenuSet', JSON.stringify(practiceModuleMenuSet));
        setStorage('permissionCodeSet', JSON.stringify(extractPermissions(practiceSideBarMenuAndPermissions?.data?.practice_role_permissions, practiceSideBarMenuAndPermissions?.data?.user_role_permissions)));
        
        // Once the practice menu sets and the permissions are stored in the local storage then force reload the page with redirection to dashboard
        window.location.href = ROUTE_DASHBOARD;
    }

    //creating a formData
    function createFormData() {
        const data = new FormData();
        let pay_location = {
            street1: payToStreetOne,
            street2: payToStreetTwo, city: payToCity, state: payToState, zip_code: payToZip, country: payToCountry
        };

        let practice_location = {
            street1: streetOne, street2: streetTwo, city: city, state: state,
            zip_code: zip, country: country
        };

        let formData = {
            'name': practiceName,
            'practice_type': practiceType,
            'npi': NPI,
            'taxonomy': taxonomy,
            'clia': CLIA,
            'tin': TIN,
            'tin_type': TINType,
            'bcbs_provider_id': bcbsProviderID,
            'tricare_provider_id': tricareProviderID,
            'uhc_provider_id': uhcProviderID,
            'commercial_provider_id': commercialProviderID,
            'practice_location': JSON.stringify(practice_location),
            'phone': phone,
            'fax': fax,
            'email': email,
            'pay_location': JSON.stringify(pay_location),
            'pay_phone': payToPhone,
            'pay_fax': payToFax,
            'pay_email': payToEmail,
            'pay_address_same': copyAddress,
            'active': !chkInactive,
            'current_practice': selectedPracticePK,
        }

        if (ispracticeLogoChanged) {
            if (practiceLogo !== "" && practiceLogo !== null) {
                formData["practice_logo"] = practiceLogo  
            } 
            setIspracticeLogoChanged(false);
        }

        for (let key in formData) {
            data.append(key, formData[key])
        }

        if (practicePK) {
            data.append('default_service_location', defServiceLoc);
        }
        else {
            if (selectedPracticePK) {
                data.append('practice_pk', selectedPracticePK);
            }
        }

        return data;
    }

    /**
     * error of requried field and other errors also handled here
     * @returns 
     */
    function hasError() {
        //require field error
        if (!isRequiredFieldsValid()) {
            return true;
        }

        //payto address validation
        if (!isPayToAddressValid()) {
            return true;
        }

        //email validation
        if (!isEmailValid()) {
            return true;
        }

        //address validation
        if (!isAddressValid()) {
            return true;
        }

        return false;
    }

    //required field empty error handled here
    function isRequiredFieldsValid() {
        if (!practiceName || !practiceType || !NPI || !taxonomy || !TIN || TIN.length !== 10 ||
            !TINType || !streetOne || !zip || !state || !city || !country || !phone || !email) {
            return false;
        }
        return true;
    }

    //payer address validationerrir handled here
    function isPayToAddressValid() {
        if (!copyAddress) {
            if (!payToStreetOne || !payToZip || !payToCity || !payToState || !payToEmail || !payToPhone) {
                return false;
            }
        }

        return true;
    }

    //email validation
    function isEmailValid() {
        const email_validation = ValidateEmail(email);
        let pay_to_email_validation = true
        if (!copyAddress) {
            pay_to_email_validation = ValidateEmail(payToEmail);
        }

        return email_validation && pay_to_email_validation;
    }

    //address validation
    function isAddressValid() {
        const streetOneError = getStreetError(streetOne);
        const streetTwoError = getStreetError(streetTwo);

        setFormError({
            ...form_error,
            ['streetOne']: streetOneError,
            ['streetTwo']: streetTwoError,
        });

        return !(streetOneError || streetTwoError);
    }

    function getStreetError(street) {
        for (const invalidAddr of arrInvalidPracticeAddrStr) {
            if (street.trim().toLowerCase().includes(invalidAddr)) {
                showNotifyWindow('show', 'error', i18n.t("userPages.practices.errorStrings.addressPOBOX"));
                return 'PO BOX not allowed';
            }
        }
        return '';
    }


    function onInactivePractice(e) {
        e.preventDefault();
        setInactivePracticeModal(true);
    }

    const getTaxonomy = (pageSize, page) => {
        const result = service.Taxonomy(pageSize, page);
        result.then(response => {
            let tmpObjArr = [];
            if (Array.isArray(response.data) && response.data.length) {
                response.data.forEach((item) => {
                    tmpObjArr.push({ "value": item.id, "label": item.full_name });
                })
                setMSelTaxonomyList(tmpObjArr);
            }
            else {
                setMSelTaxonomyList(tmpObjArr);
            }
        });
    }

    useEffect(() => {
        getTaxonomy(20, 0);
    }, []);


    function openImage() {
        document.getElementById("file_practiceLogo")?.click();
        setIspracticeLogoChanged(true);
    }

    function onChangeShowImageCropperModalWindow(newValue) {
        setShowImageCropperModalWindow(newValue);
    }

    function onCroppedImageChange(newValue) {
        setPracticeLogo(newValue);
        setImageUrl(URL.createObjectURL(newValue));
    }

    function onRemoveImage() {
        setImgUrl('');
        setImageName('');
        document.getElementById('file_practiceLogo').value = '';
        setImageUrl(profileImage);
        setIspracticeLogoChanged(true);
    }


    if (gotoList) {
        history.push(ROUTE_PRACTICES, { practiceName: practiceName ,callbackactivepage :location?.callbackactive})
    }

    function backToRefering() {
        history.push(ROUTE_PRACTICES)
    }

    return (
        <React.Fragment>
            <div className="col-md-12">
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <Form id="form_dataEntry_practice" autoComplete="off" encType="multipart/form-data" className='margin-right15'>
                    <Form.Group>
                        <div className="box ">
                            <div className="box-head ">
                                <TabContext value={activeTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <StyledTabList
                                            TabIndicatorProps={{
                                                children: <CustomIndicator />
                                            }}
                                            onChange={onTabChange}
                                            scrollable
                                            scrollButtons="auto"
                                            aria-label="lab API tabs example"
                                            variant="scrollable"
                                        > 
                                            {/* <TabList onChange={onTabChange} aria-label="lab API tabs example"> */}
                                            <Tab label={i18n.t("userPages.practices.labelTabPracticeInfo")} value={TAB1} className='tabMainMenu' />
                                            {practicePK &&
                                                <Tab label={i18n.t("userPages.practices.labelTabOtherLocations")} value={TAB2} className='tabMainMenu' />
                                            }
                                            {
                                                practicePK ?
                                                    <Tab label={i18n.t('statementManagement.practiceRules.tabName')} value={TAB3} className='tabMainMenu' />
                                                    : <></>
                                            }
                                            {/* </TabList> */}
                                        </StyledTabList> 
                                    </Box>
                                    <TabPanel value={TAB1} className='searchBoxTab box-content-white border-radius-topleft-0'>
                                        <div className="padding-left15 padding-top20">
                                            <div className="basic-info-padding">

                                                <div className="row padding-right15">
                                                    <div className="col-12">

                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group" >
                                                                    <TextInput type="text" id="practiceName" name="practiceName" required={true} label={i18n.t("userPages.practices.labelPracticeName")} onValueChange={onHandleChange} value={practiceName} />
                                                                </div>
                                                            </div>
                                                            {practiceID && practiceID.trim().length > 0 &&
                                                                <>
                                                                    <div className="col-2" >
                                                                        <div className="form-group padding-left-15" >
                                                                            <TextInput type="text" disabled={true} className="disabledInput read-only" id="practiceID" name="practiceID" label={i18n.t('userPages.practices.labelPracticeID')} readOnly={true} value={practiceID} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <SelectInput data={practiceTypesList} name="practiceType" required={true} id="practiceType" value={practiceType} setValue={setPracticeType} onValueChange={onHandleChange} label={i18n.t('userPages.practices.labelPracticeType')} className={form_error.practiceType ? 'input-error' : ""} />
                                                                    </div>
                                                                </>
                                                            }
                                                            {practiceID.trim().length === 0 &&
                                                                <div className="col-2" >
                                                                    <SelectInput data={practiceTypesList} name="practiceType" required={true} id="practiceType" value={practiceType} setValue={setPracticeType} onValueChange={onHandleChange} label={i18n.t('userPages.practices.labelPracticeType')} className={form_error.practiceType ? 'input-error' : ""} />
                                                                </div>
                                                            }
                                                            {defaultLocationList.length > 0 &&
                                                                <div className="col-2">
                                                                    <div>
                                                                        <SelectInput data={defaultLocationList} name="defServiceLoc" id="defServiceLoc" value={defServiceLoc} setValue={setDefServiceLoc} onValueChange={onHandleChange} label={i18n.t('userPages.practices.labelDefServiceLoc')} className={form_error.practiceType ? 'input-error' : ""} selectOptionRemove={true} />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-2">
                                                                <div className="form-group" >
                                                                    <TextInput type="text" id="CLIA" name="CLIA" label={i18n.t("userPages.practices.labelCLIA")} onValueChange={onHandleChange} className={form_error.CLIA ? 'input-error' : ""} value={CLIA} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-2">
                                                                <div className="">
                                                                        <div className="div-float-right searchNpi" style={{ position: "absolute", right: "4px" }} onClick={showSearchNPI} >
                                                                            <Link to="#">{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                                                        </div>
                                                                    <div className="form-group" >
                                                                        <TextInput type="text" id="NPI" name="NPI" required={true} label={i18n.t("userPages.practices.labelNPI")} onValueChange={onHandleChange} className={form_error.NPI ? 'input-error' : ""} value={NPI} rightLink={i18n.t("userPages.practices.labelSearchNPI")} disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className={"form-group " + (!taxonomy ? "input-error" : "")}>
                                                                    <Multiselect value={taxonomy}
                                                                        onChange={handleMultiselect}
                                                                        multiple={false}
                                                                        options={mSelTaxonomyList}
                                                                        label={i18n.t('customerSetup.provider.labelTaxonomy')}
                                                                        labelPlural={i18n.t('customerSetup.provider.labelTaxonomy')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className="form-group">
                                                                    <SelectInput data={TIN_TYPES} name="TINType" id="TINType" required={true} value={TINType} onValueChange={onHandleChange} label={i18n.t('userPages.practices.labelTINType')} className={form_error.TINType ? 'input-error' : ""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group" >
                                                                    <TextInput type="text" id="TIN" name="TIN" onValueChange={onHandleChange} defaultClassName={(TIN.length < 10) ? "input-error" : ""} value={TIN} required={true} maxLength={10} label={i18n.t("userPages.practices.labelTaxIdentificationNum")} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row padding-right15">
                                                    <div className="col-2">
                                                        <div className="form-group">
                                                            <TextInput type="text" id="bcbsProviderID" name="bcbsProviderID" label={i18n.t("userPages.practices.labelBcbsProviderID")} onValueChange={onHandleChange} value={bcbsProviderID} />
                                                        </div>
                                                    </div>

                                                    
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <TextInput type="text" id="tricareProviderID" name="tricareProviderID" label={i18n.t("userPages.practices.labelTricareProvideID")} onValueChange={onHandleChange} value={tricareProviderID} />
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="form-group">
                                                            <TextInput type="text" id="uhcProviderID" name="uhcProviderID" label={i18n.t("userPages.practices.labelUhcProviderID")} onValueChange={onHandleChange} value={uhcProviderID} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <TextInput type="text" id="commercialProviderID" name="commercialProviderID" label={i18n.t("userPages.practices.labelCOmmercialProviderID")} onValueChange={onHandleChange} value={commercialProviderID} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row padding-right15">
                                                    <div className="div-inline padding-left-10">
                                                        <label className="padding-left5 margin-top-3px">{i18n.t('userPages.practices.labelPhotoAddress')}</label>
                                                    </div>

                                                </div>

                                                <div className="row padding-right15">

                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="streetOne" name="streetOne" required={true} label={i18n.t("userPages.practices.labelAddress1")} onValueChange={onHandleChange} value={streetOne} defaultClassName={form_error.streetOne ? 'input-error' : ""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <TextInput type="text" id="streetTwo" name="streetTwo" label={i18n.t("userPages.practices.labelAddress2")} onValueChange={onHandleChange} value={streetTwo} defaultClassName={form_error.streetTwo ? 'input-error' : ""} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <CityInput type="text" id="city" name="city" value={city} onValueChange={onHandleChange} required={true} className={form_error.city ? 'input-error' : ""} label={i18n.t("userPages.practices.labelCity")}
                                                                    zip={zip} setValue={setCity} />
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group ">
                                                                    <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')} className={form_error.state ? 'input-error' : ""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group ">
                                                                    <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""}
                                                                        required={true} label={i18n.t("userPages.practices.labelZip")}
                                                                        setlocState={setState} setLocCountry={setCountry} setLocZipCode={setZip}
                                                                        setLocCity={setCity} defaultValue={zip} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="form-group ">
                                                                    <TextInput required={true} type="text" id="country" name="country" label={i18n.t("userPages.practices.labelCountry")} onValueChange={onHandleChange} value={country} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <PhoneInput type="text" id="phone" name="phone" required={true} label={i18n.t("userPages.practices.labelPhone")} onValueChange={setPhone} value={phone} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <PhoneInput type="text" id="fax" name="fax" label={i18n.t("userPages.practices.labelFax")} onValueChange={setFax} className={form_error.fax ? 'input-error' : ""} value={fax} />
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group padding-top3">
                                                                    <EmailInput id="email" name="email" label={i18n.t("userPages.practices.labelEmail")} onValueChange={onHandleChange} required={true} value={email} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="">
                                                            <ProfileCard image={imageUrl} onRemoveImage={onRemoveImage} onClick={openImage} alt={"Practice Name"}>
                                                                {imageUrl &&
                                                                    <p className={isLogoValid ? 'logo-txt d-none' : 'text-red logo-txt d-none'}>
                                                                        {(form_error.practiceLogo.length > 0) &&
                                                                            <div>
                                                                                <small>{form_error.practiceLogo}</small>
                                                                            </div>
                                                                        }
                                                                        <input type="file" id="file_practiceLogo" className='d-none' name="file_practiceLogo" onChange={onHandleChange} accept=".png, .jpg, .jpeg" />
                                                                    </p>
                                                                }
                                                            </ProfileCard>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* PAY TO ADDRESS */}
                                                <div className="row sub-heading padding-right15">
                                                    <div className="div-inline padding-top10 mr-3">
                                                        <label className="margin-top-3px padding-left-10">{i18n.t('userPages.practices.labelPayToInfo')}</label>
                                                    </div>
                                                    <div className="max-width-3">
                                                        <div className="form-group">
                                                            <div className="custom-checkbox lh-0">
                                                                <input
                                                                    type="checkbox"
                                                                    id={'chkSameAsAbove'}
                                                                    onClick={(e) => onCopyAddress(e)}
                                                                    checked={copyAddress}
                                                                />
                                                                <label className="checkbox top-14" htmlFor={'chkSameAsAbove'}></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="padding-top5 padding-left20">
                                                        <label className="padding-top5 margin-top-3px">{i18n.t('userPages.practices.labelSameAsAbove')}</label>
                                                    </div>

                                                </div>
                                                {!copyAddress ?
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="form-group ">
                                                                <TextInput type="text" id="payToStreetOne" name="payToStreetOne" required={true} label={i18n.t("userPages.practices.labelAddress1")} onValueChange={onHandleChange} className={form_error.payToStreetOne ? 'input-error' : ""} value={payToStreetOne} />
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group ">
                                                                <TextInput type="text" id="payToStreetTwo" name="payToStreetTwo" label={i18n.t("userPages.practices.labelAddress2")} onValueChange={onHandleChange} value={payToStreetTwo} />
                                                            </div>
                                                        </div>
                                                    </div> : ""
                                                }
                                                {!copyAddress ?
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <CityInput type="text" id="payToCity" name="payToCity" required={true} value={payToCity} onValueChange={onHandleChange} className={form_error.city ? 'input-error' : ""} label={i18n.t("userPages.practices.labelCity")}
                                                                zip={payToZip} setValue={setPayToCity} />
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="form-group ">
                                                                <TextInput type="text" id="payToState" name="payToState" required={true} label={i18n.t("userPages.practices.labelState")} onValueChange={onHandleChange} className={form_error.payToState ? 'input-error' : ""} value={payToState} />
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="form-group ">
                                                                <GeoCodeSearch id="payToZip" name="payToZip"
                                                                    className={form_error.payToZip ? 'input-error' : ""} required={true}
                                                                    label={i18n.t("userPages.practices.labelZip")}
                                                                    setlocState={setPayToState} setLocCountry={setPayToCountry} setLocZipCode={setPayToZip}
                                                                    setLocCity={setPayToCity} defaultValue={payToZip} />
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="form-group ">
                                                                <TextInput type="text" id="payToCountry" name="payToCountry" label={i18n.t("userPages.practices.labelCountry")} onValueChange={onHandleChange} className={form_error.payToCountry ? 'input-error' : ""} value={payToCountry} />
                                                            </div>
                                                        </div>
                                                    </div> : ""}
                                                {!copyAddress ?
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <div className="form-group">
                                                                <PhoneInput type="text" id="payToPhone" name="payToPhone" required={true} label={i18n.t("userPages.practices.labelPhone")} onValueChange={setPayToPhone} className={form_error.payToPhone ? 'input-error' : ""} value={payToPhone} />
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="form-group">
                                                                {/* <TextInput type="text" id="payToFax" name="payToFax" label={i18n.t("userPages.practices.labelFax")} onValueChange={onHandleChange} className={form_error.payToFax ? 'input-error' : ""} value={payToFax} /> */}
                                                                <PhoneInput type="text" id="payToFax" name="payToFax" label={i18n.t("userPages.practices.labelFax")} onValueChange={setPayToFax} className={form_error.payToFax ? 'input-error' : ""} value={payToFax} />
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group padding-top3">
                                                                <EmailInput id="payToEmail" name="payToEmail" label={i18n.t("userPages.practices.labelEmail")} onValueChange={onHandleChange} required={true} value={payToEmail} />
                                                            </div>
                                                        </div>
                                                    </div> : ""}
                                                <CustomizedSmallDialogs showModal={inactivePracticeModal} setShowModalWindow={setInactivePracticeModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes" onHide={onHide}
                                                    inactveItem={onSavePractice} resetForm={onHide} buttonStyle='danger' >
                                                    {i18n.t("commons.confrimInactive") + " practice?"}
                                                </CustomizedSmallDialogs>
                                                {
                                                    practicePK &&
                                                    <div className="row pb-4 padding-right15">
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <div className="custom-checkbox lh-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={'chkInactive'}
                                                                        onChange={(e) => changeActive(e)}
                                                                        checked={chkInactive}
                                                                    />
                                                                    <label className="checkbox margint-top5 cursor-pointer" htmlFor={'chkInactive'}></label>
                                                                        <label className="padding-top15 padding-left15 cursor-pointer" htmlFor={'chkInactive'} >{i18n.t('userPages.practices.labelInactive')}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 justify-right">
                                                            {activeTab && activeTab === TAB1 &&
                                                                <>
                                                                    <button type="button" onClick={backToRefering} className="btn btn-common-height35 btn-secondary px-4 mr-3 margin-top2">{i18n.t("commons.cancel")}</button>
                                                                    {checkPermission(permission_key_values_accountsetup.account_setup_practice_management_modify) && <>
                                                                    {/* <button className="btn btn-primary-blue btn-common-height35 width-75" onClick={chkInactive ? (e) => onInactivePractice(e) : (e) => onSavePractice(e)}>
                                                                         {i18n.t('buttons.save')}
                                                                     </button> */}
                                                                    <CommonButton
                                                                    label={i18n.t('buttons.save')}     
                                                                    variant="contained"
                                                                    onClick={chkInactive ? (e) => onInactivePractice(e) : (e) => onSavePractice(e)}/></>
                                                                    }
                                                                </>
                                                            }
                                                          
                                                        </div>
                                                    </div>
                                                }
                                                {!practicePK &&

                                                    <div className="col-12 py-5 pb-4 padding-right15">
                                                        {activeTab && activeTab === TAB1 &&
                                                            <div className="justify-right">
                                                                <button type="button" onClick={backToRefering} className="btn btn-secondary px-4 mr-3 btn-common-height35 ">
                                                                    {i18n.t("commons.cancel")}
                                                                </button>
                                                                {checkPermission(permission_key_values_accountsetup.account_setup_practice_management_add, customer_admin_privilege ) && 
                                                                //  <button className="btn btn-primary-blue btn-common-height35 width-75" onClick={chkInactive ? (e) => onInactivePractice(e) : (e) => onSavePractice(e)}>
                                                                //     {i18n.t('buttons.save')}
                                                                // </button>
                                                                <CommonButton
                                                                label={i18n.t('buttons.save')}    
                                                                variant="contained"
                                                                onClick={chkInactive ? (e) => onInactivePractice(e) : (e) => onSavePractice(e)}/> 
                                                                }

                                                            </div>
                                                        }
                                                         
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={TAB2} className='searchBoxTab box-content-white'>
                                        <PracticeLocations practicePK={practicePK} getPracticeLocationData={getPracticeLocationData} />
                                    </TabPanel>
                                    <TabPanel value={TAB3} className='searchBoxTab box-content-white'>
                                        <PracticeStatementConfig practicePk={practicePK} showNotifyWindow={showNotifyWindow} />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </div >

            < ImageCrop imgUrl={imgUrl} imageName={imageName} show={showImageCropperModalWindow} showModal={onChangeShowImageCropperModalWindow} changeCroppedImage={onCroppedImageChange} />
            <CustomizedDialogs showModal={showModalWindow} type="save" header={i18n.t("userPages.practices.labelSearchNPI")} setShowModalWindow={setShowModalWindow} onHide={onHide} resetForm={onHide} selectNPIBtn={false} hideSave={true} >
                <NPISearch setSelectedNPI={setSelectedNPI} searchNPIName={practiceName} providerType={PROVIDER_TYPE_ORGANIZATION} npi={NPI} />
            </CustomizedDialogs>
        </React.Fragment >
    )
}
export default PracticesAdd