export const permissions = {
    "1": "dashBoard_view",
    "2": "dashBoard_add",
    "3": "dashBoard_modify",
    "4": "patients",
    "5": "patients_patients_list_view",
    "6": "patients_patients_list_add",
    "7": "patients_patients_list_modify",
    "8": "patients_run_eligibility_add",
    "9": "patients_run_eligibility_view",
    "10": "patients_run_eligibility_modify",
    "11": "patients_statement_management_view",
    "12": "patients_statement_management_add",
    "13": "patients_statement_management_modify",
    "14": "patients_payments_plans_view",
    "15": "patients_payments_plans_add",
    "16": "patients_payments_plans_modify",
    "17": "patients_patient_ledger_view",
    "18": "patients_patient_ledger_add",
    "19": "patients_patient_ledger_modify",
    "20": "patients_patient_letters_view",
    "21": "patients_patient_letters_add",
    "22": "patients_patient_letters_modify",
    "23": "claims",
    "24": "claims_search_claims_view",
    "25": "claims_search_claims_add",
    "26": "claims_search_claims_modify",
    "27": "claims_claim_filter_view",
    "28": "claims_claim_filter_add",
    "29": "claims_claim_filter_modify",
    "30": "claims_ar_claims_view",
    "31": "claims_ar_claims_add",
    "32": "claims_ar_claims_modify",
    "33": "claims_paper_claims_view",
    "34": "claims_paper_claims_add",
    "35": "claims_paper_claims_modify",
    "36": "payments",
    "37": "payments_post_payments_view",
    "38": "payments_post_payments_add",
    "39": "payments_post_payments_modify",
    "40": "payments_era_posting_view",
    "41": "payments_era_posting_add",
    "42": "payments_era_posting_modify",
    "43": "payments_view_payments_view",
    "44": "payments_view_payments_add",
    "45": "payments_view_payments_modify",
    "46": "messages",
    "47": "messages_compose_view",
    "48": "messages_compose_add",
    "49": "messages_compose_modify",
    "50": "messages_inbox_sentMails_drafts_view",
    "51": "messages_inbox_sentMails_drafts_add",
    "52": "messages_inbox_sentMails_drafts_modify",
    "53": "messages_templates_view",
    "54": "messages_templates_add",
    "55": "messages_templates_modify",
    "56": "task_view",
    "57": "task_add",
    "58": "task_modify",
    "59": "user_logs_view",
    "60": "user_logs_add",
    "61": "user_logs_modify",
    "62": "reports",
    "63": "reports_viewers_view",
    "64": "reports_viewers_add",
    "65": "reports_viewers_modify",
    "66": "reports_builders_view",
    "67": "reports_builders_add",
    "68": "reports_builders_modify",
    "69": "interface",
    "70": "interface_search_view",
    "71": "interface_search_add",
    "72": "interface_search_modify",
    "73": "interface_provider_name_matching_view",
    "74": "interface_provider_name_matching_add",
    "75": "interface_provider_name_matching_modify",
    "76": "interface_payer_name_matching_view",
    "77": "interface_payer_name_matching_add",
    "78": "interface_payer_name_matching_modify",
    "79": "interface_facility_name_matching_view",
    "80": "interface_facility_name_matching_add",
    "81": "interface_facility_name_matching_modify",
    "82": "interface_test_code_setup_view",
    "83": "interface_test_code_setup_add",
    "84": "interface_test_code_setup_modify",
    "85": "account_setup",
    "86": "account_setup_practice_management_view",
    "87": "account_setup_practice_management_add",
    "88": "account_setup_practice_management_modify",
    "89": "account_setup_provider_management_view",
    "90": "account_setup_provider_management_add",
    "91": "account_setup_provider_management_modify",
    "92": "account_setup_referring_provider_view",
    "93": "account_setup_referring_provider_add",
    "94": "account_setup_referring_provider_modify",
    "95": "account_setup_facility_management_view",
    "96": "account_setup_facility_management_add",
    "97": "account_setup_facility_management_modify",
    "98": "account_setup_insurance_management_view",
    "99": "account_setup_insurance_management_add",
    "100": "account_setup_insurance_management_modify",
    "101": "account_setup_fee_schedule_management_view",
    "102": "account_setup_fee_schedule_management_add",
    "103": "account_setup_fee_schedule_management_modify",
    "104": "account_setup_code_management_view",
    "105": "account_setup_code_management_add",
    "106": "account_setup_code_management_modify",
    "107": "administration",
    "108": "administration_user_management_view",
    "109": "administration_user_management_add",
    "110": "administration_user_management_modify",
    "111": "administration_page_locks_view",
    "112": "administration_page_locks_add",
    "113": "administration_page_locks_modify",
    "114": "settings",
    "115": "settings_practice_dictionaries_view",
    "116": "settings_practice_dictionaries_add",
    "117": "settings_practice_dictionaries_modify",
    "118": "settings_csv_import_templates_view",
    "119": "settings_csv_import_templates_add",
    "120": "settings_csv_import_templates_modify",
    "121": "administration_roles_and_permissions_view",
    "122": "administration_roles_and_permissions_add",
    "123": "administration_roles_and_permissions_modify"
}


export const permission_key_values = {
    "dashBoard_view": 1,
    "dashBoard_add": 2,
    "dashBoard_modify": 3,
    "patients": 4,
    "patients_patients_list_view": 5,
    "patients_patients_list_add": 6,
    "patients_patients_list_modify": 7,
    "patients_run_eligibility_add": 8,
    "patients_run_eligibility_view": 9,
    "patients_run_eligibility_modify": 10,
    "patients_statement_management_view": 11,
    "patients_statement_management_add": 12,
    "patients_statement_management_modify": 13,
    "patients_payments_plans_view": 14,
    "patients_payments_plans_add": 15,
    "patients_payments_plans_modify": 16,
    "patients_patient_ledger_view": 17,
    "patients_patient_ledger_add": 18,
    "patients_patient_ledger_modify": 19,
    "patients_patient_letters_view": 20,
    "patients_patient_letters_add": 21,
    "patients_patient_letters_modify": 22,
    "claims": 23,
    "claims_search_claims_view": 24,
    "claims_search_claims_add": 25,
    "claims_search_claims_modify": 26,
    "claims_claim_filter_view": 27,
    "claims_claim_filter_add": 28,
    "claims_claim_filter_modify": 29,
    "claims_ar_claims_view": 30,
    "claims_ar_claims_add": 31,
    "claims_ar_claims_modify": 32,
    "claims_paper_claims_view": 33,
    "claims_paper_claims_add": 34,
    "claims_paper_claims_modify": 35,
    "payments": 36,
    "payments_post_payments_view": 37,
    "payments_post_payments_add": 38,
    "payments_post_payments_modify": 39,
    "payments_era_posting_view": 40,
    "payments_era_posting_add": 41,
    "payments_era_posting_modify": 42,
    "payments_view_payments_view": 43,
    "payments_view_payments_add": 44,
    "payments_view_payments_modify": 45,
    "messages": 46,
    "messages_compose_view": 47,
    "messages_compose_add": 48,
    "messages_compose_modify": 49,
    "messages_inbox_sentMails_drafts_view": 50,
    "messages_inbox_sentMails_drafts_add": 51,
    "messages_inbox_sentMails_drafts_modify": 52,
    "messages_templates_view": 53,
    "messages_templates_add": 54,
    "messages_templates_modify": 55,
    "task_view": 56,
    "task_add": 57,
    "task_modify": 58,
    "user_logs_view": 59,
    "user_logs_add": 60,
    "user_logs_modify": 61,
    "reports": 62,
    "reports_viewers_view": 63,
    "reports_viewers_add": 64,
    "reports_viewers_modify": 65,
    "reports_builders_view": 66,
    "reports_builders_add": 67,
    "reports_builders_modify": 68,
    "interface": 69,
    "interface_search_view": 70,
    "interface_search_add": 71,
    "interface_search_modify": 72,
    "interface_provider_name_matching_view": 73,
    "interface_provider_name_matching_add": 74,
    "interface_provider_name_matching_modify": 75,
    "interface_payer_name_matching_view": 76,
    "interface_payer_name_matching_add": 77,
    "interface_payer_name_matching_modify": 78,
    "interface_facility_name_matching_view": 79,
    "interface_facility_name_matching_add": 80,
    "interface_facility_name_matching_modify": 81,
    "interface_test_code_setup_view": 82,
    "interface_test_code_setup_add": 83,
    "interface_test_code_setup_modify": 84,
    "account_setup": 85,
    "account_setup_practice_management_view": 86,
    "account_setup_practice_management_add": 87,
    "account_setup_practice_management_modify": 88,
    "account_setup_provider_management_view": 89,
    "account_setup_provider_management_add": 90,
    "account_setup_provider_management_modify": 91,
    "account_setup_referring_provider_view": 92,
    "account_setup_referring_provider_add": 93,
    "account_setup_referring_provider_modify": 94,
    "account_setup_facility_management_view": 95,
    "account_setup_facility_management_add": 96,
    "account_setup_facility_management_modify": 97,
    "account_setup_insurance_management_view": 98,
    "account_setup_insurance_management_add": 99,
    "account_setup_insurance_management_modify": 100,
    "account_setup_fee_schedule_management_view": 101,
    "account_setup_fee_schedule_management_add": 102,
    "account_setup_fee_schedule_management_modify": 103,
    "account_setup_code_management_view": 104,
    "account_setup_code_management_add": 105,
    "account_setup_code_management_modify": 106,
    "administration": 107,
    "administration_user_management_view": 108,
    "administration_user_management_add": 109,
    "administration_user_management_modify": 110,
    "administration_page_locks_view": 111,
    "administration_page_locks_add": 112,
    "administration_page_locks_modify": 113,
    "settings": 114,
    "settings_practice_dictionaries_view": 115,
    "settings_practice_dictionaries_add": 116,
    "settings_practice_dictionaries_modify": 117,
    "settings_csv_import_templates_view": 118,
    "settings_csv_import_templates_add": 119,
    "settings_csv_import_templates_modify": 120,
    "administration_roles_and_permissions_view": 121,
    "administration_roles_and_permissions_add": 122,
    "administration_roles_and_permissions_modify": 123
}

export const permission_key_values_payments = {
    "payments_era_posting_add": 'pms_era_posting_sub_module_add',
    "payments_view_payments_modify": 'pms_view_payments_sub_module_modify',
    "post_payments": "pms_post_payments_sub_module_add",
    "view_payments": "pms_view_payments_sub_module_view",
    "era_posting": "pms_era_posting_sub_module_view",
    "era_posting_edit": "pms_can_change_era_payment_header"
}

export const permission_key_values_chatbot = "pms_dashboard_module_view";

export const permission_key_values_patient = {
    "patients_patients_list_view": "pms_can_view_patient_list",
    "patient_list_sub_module_view": "pms_patient_list_sub_module_view",
    "patients_patients_list_add": "pms_patient_list_sub_module_add",
    "patients_patients_list_modify": "pms_patient_list_sub_module_modify",
    "can_add_insurance_info": "pms_can_add_insurance_info",
    "can_change_insurance_info": "pms_can_change_insurance_info",
    "can_add_authorization": "pms_can_add_authorization",
    "can_add_patient_alert": "pms_can_add_patient_alert",
    "can_change_patient_alerts": "pms_can_change_patient_alerts",
    "can_delete_patient_alerts": "pms_can_delete_patient_alerts",
    "can_add_patient_claim_notes": "pms_can_add_patient_claim_notes",
    "can_change_patient_claim_notes": "pms_can_change_patient_claim_notes",
    "can_delete_patient_claim_notes": "pms_can_delete_patient_claim_notes",
    "can_change_authorization": "pms_can_change_authorization",
    "can_delete_authorization": "pms_can_delete_authorization",
    "can_view_eligibility_insurance_info": "pms_can_view_eligibility_insurance_info",
    "can_delete_insurance_card": "pms_can_delete_insurance_card",
    "can_change_insurance_card": "pms_can_change_insurance_card",
    "import_batch_tab_view": "pms_import_batch_tab_view",
    "batch_eligibility_check_tab_view": "pms_batch_eligibility_check_tab_view",
    "can_view_response_html": "pms_can_view_response_html",
    "import_patient_list_tab_modify": "pms_import_patient_list_tab_modify",
    "statement_management_view": "pms_can_view_patient_statement",
    "statement_configuration_tab_add": "pms_statement_configuration_tab_add",
    "statement_configuration_tab_modify": "pms_statement_configuration_tab_modify",
    "payment_plans_sub_module_modify": "pms_payment_plans_sub_module_modify",
    "payment_plans_sub_module_add": "pms_payment_plans_sub_module_add",
    "patient_ledger_sub_module_modify": "pms_patient_ledger_sub_module_modify",
    "pms_can_upload_patients_file": "pms_can_upload_patients_file",
    "pms_can_view_patient_upload_files_list": "pms_can_view_patients_uploaded_files_list",
    "pms_can_delete_uploaded_patients_file": "pms_can_delete_uploaded_patients_file",
    "pms_can_view_patient_uploaded_file": "pms_can_view_patient_uploaded_file"
}

export const permission_key_values_claim = {
    "claims_search_claims_view": "pms_search_claims_sub_module_view",
    "claims_search_claims_add": "pms_search_claims_sub_module_add",
    "claims_search_claims_modify": "pms_search_claims_sub_module_modify",
    "claims_search_tab_module_view": "pms_search_claims_search_tab_module_view",
    "search_claims_last_10_tab_module_view": "pms_search_claims_last_10_tab_module_view",
    "can_view_filtered_claim_list": "pms_can_view_filtered_claim_list",
    "can_add_warnings": "pms_can_add_warnings",
    "patient_list_sub_module_view": "pms_patient_list_sub_module_view",
    "search_claims_sub_module_modify": "pms_search_claims_sub_module_modify",
    "post_payments_sub_module_view": "pms_post_payments_sub_module_view",
    "can_add_patient_claim_notes": "pms_can_add_patient_claim_notes",
    "can_change_patient_claim_notes": "pms_can_change_patient_claim_notes",
    "patient_ledger_sub_module_view": "pms_patient_ledger_sub_module_view",
    "audit_logs_module_view": "pms_audit_logs_module_view",
    "can_view_paper_claim_list": "pms_can_view_paper_claim_list",
    "can_view_paper_claim_form": "pms_can_view_paper_claim_form",
    "claims_claim_filter_view": "pms_claim_filter_sub_module_view",
    "claims_claim_filter_add": "",
    "claims_claim_filter_modify": "",
    "load_claims_tab_view": "pms_load_claims_tab_view",
    "my_ar_tab_view": "pms_my_ar_tab_view",
    "export_ar_tab_view": "pms_load_claims_tab_view",
    "assignlist_ar_tab_action": "pms_load_claims_tab_modify",
    "ar_claims_sub_module_view": "pms_ar_claims_sub_module_view",
    "claims_ar_claims_add": 31,
    "claims_ar_claims_modify": "pms_ar_claims_sub_module_modify",
    "claims_paper_claims_view": "pms_paper_claims_sub_module_view",
    "claims_paper_claims_add": 34,
    "claims_paper_claims_modify": "pms_paper_claims_sub_module_modify",
}
export const permission_key_values_messages = {
  
    "can_add_message_template": "pms_can_add_message_template",
    "can_use_template": "pms_can_view_message_template_list",
    "can_modify_inbox_messages": "pms_inbox_message_sub_module_modify",
    "can_add_reply_messages": "pms_compose_message_sub_module_add",
    "can_modify_sentMails": "pms_inbox_message_sub_module_modify",
    "can_add_message_draft": "pms_can_add_user_message_or_draft", 
    "can_modify_message_draft": "pms_draft_message_sub_module_modify",
    "can_add_send_draft_messages": "pms_compose_message_sub_module_add",
    "can_add_templates_messages": "pms_message_template_sub_module_add",
    "messages_templates_modify": "pms_message_template_sub_module_modify",
    "inbox_message_sub_module_view": "pms_inbox_message_sub_module_view",
    "sent_message_sub_module_view": "pms_sent_message_sub_module_view",
    "draft_message_sub_module_view": "pms_draft_message_sub_module_view"
}


export const permission_key_values_tasks = {
    "task_add": "pms_task_module_add",
    "can_modify_task": "pms_task_module_modify",
    "patient_task_link_type": "pms_patient_list_sub_module_view",
    "claims_task_link_type": "pms_search_claims_sub_module_view"
}

export const super_admin_permission_key_value = {
    "dictionary_add": "pms_dictionaries_sub_module_add"
}

export const permission_key_values_accountsetup = {
    "account_setup_practice_management_add": "pms_practice_management_sub_module_add",
    "account_setup_practice_management_modify": "pms_practice_management_sub_module_modify",
    "account_setup_provider_management_add": "pms_provider_management_sub_module_add",
    "account_setup_provider_management_modify": "pms_provider_management_sub_module_modify",
    "account_setup_referring_provider_add": "pms_referring_providers_sub_module_add",
    "account_setup_referring_provider_modify": "pms_referring_providers_sub_module_modify",
    "account_setup_facility_management_add": "pms_facility_management_sub_module_add",
    "account_setup_facility_management_modify": "pms_facility_management_sub_module_modify",
    "account_setup_insurance_management_view":"pms_insurance_management_sub_module_view",
    "account_setup_insurance_management_add": "pms_insurance_management_sub_module_add",
    "account_setup_insurance_management_modify": "pms_insurance_management_sub_module_modify",
    "account_setup_fee_schedule_management_view": "pms_fee_schecdule_management_sub_module_view",
    "account_setup_fee_schedule_management_add": "pms_fee_schecdule_management_sub_module_add",
    "account_setup_fee_schedule_management_modify": "pms_fee_schecdule_management_sub_module_modify",
    "account_setup_code_management_view": "pms_code_management_sub_module_view",
    "account_setup_code_management_add": "pms_code_management_sub_module_add",
    "account_setup_code_management_modify": "pms_code_management_sub_module_modify",

}

export const super_admin_privileges = {
    "super_admin_full_privilege": "pms_super_admin_privilege",
}
export const super_admin_roles_permission_privilege = {
    "super_admin_roles_permission": "pms_admin_roles_and_permissions_sub_module_add"
}

export const pms_staff_user_privilege = "pms_staff_user_privilege"

export const permission_key_values_auditLogs = {
    "can_view_patient": "pms_patient_list_sub_module_view",
    "can_view_claims": "pms_search_claims_sub_module_view",
    "can_view_practices": "pms_practice_management_sub_module_add",
    "can_view_userManagement": "pms_user_management_sub_module_view",
    "can_view_providerManagement": "pms_provider_management_sub_module_view",
    "can_view_referringProvider": "pms_referring_providers_sub_module_view",
    "can_view_facility": "pms_facility_management_sub_module_view",
    "can_view_task": "pms_task_sub_module_view",
    "can_view_activePayments": "pms_view_payments_sub_module_view",
    "can_view_viewPayments": "pms_view_payments_sub_module_view",
    "can_view_sendMessage": "pms_sent_message_sub_module_view",
    "can_view_inboxMessage": "pms_inbox_message_sub_module_view"

}
export const customer_admin_privilege = "pms_customer_admin_privilege"

export const permission_key_values_administration = {
    "associate_user_add": "pms_can_add_associate_user",
    "associate_user_modify": "pms_user_management_sub_module_modify",
    "associate_user_delete": "pms_can_delete_associate_user",
    "page_unlock_access": "pms_can_add_page_lock_status",
    "practice_role_create": "pms_can_add_practice_role",
    "practice_role_modify": "pms_roles_and_permissions_sub_module_modify",
    "practice_role_delete": "pms_can_delete_practice_role_permissions",
    "practice_role_add": "pms_roles_and_permissions_sub_module_add"
}

export const module_submodule_permission={
    "dashboard_module_view":"pms_dashboard_module_view",
    "admin_dictionaries_sub_module_view":"pms_dictionaries_sub_module_view",
    "code_management_sub_module_view":"pms_code_management_sub_module_view",
    "practice_dictionaries_sub_module_view":"pms_practice_dictionaries_sub_module_view",
    "patient_run_eligibility_sub_module_view":"pms_patient_run_eligibility_sub_module_view",
    "patients_statement_management_sub_module_view":"pms_patients_statement_management_sub_module_view",
    "payment_plans_sub_module_view":"pms_payment_plans_sub_module_view",
    "patient_ledger_sub_module_view":"pms_patient_ledger_sub_module_view",
    "payments_module_view":"pms_payments_module_view",
    "message_module_view":"pms_message_module_view",
    "inbox_message_sub_module_view":"pms_inbox_message_sub_module_view",
    "compose_message_sub_module_add":"pms_compose_message_sub_module_add",
    "sent_message_sub_module_view":"pms_sent_message_sub_module_view",
    "sent_message_sub_module_modify":"pms_sent_message_sub_module_modify",
    "message_template_sub_module_view": "pms_message_template_sub_module_view",
    "message_template_sub_module_delete": "pms_can_delete_message_template",
    "task_module_view":"pms_task_module_view",
    "charges_and_debit_sub_module_view":"pms_charges_and_debit_sub_module_view",
    "payments_and_adjustments_sub_module_view":"pms_payments_and_adjustments_sub_module_view",
    "open_ar_sub_module_view":"pms_open_ar_sub_module_view",
    "practice_management_sub_module_view":"pms_practice_management_sub_module_view",
    "page_lock_sub_module_view":"pms_page_lock_sub_module_view",
    "roles_and_permissions_sub_module_view":"pms_roles_and_permissions_sub_module_view",
    "csv_import_template_sub_module_view":"pms_csv_import_template_sub_module_view",
    "admin_customer_management_sub_module_view":"pms_admin_customer_management_sub_module_view", 
    "admin_user_management_sub_module_view":"pms_admin_user_management_sub_module_view",
    "page_lock_sub_module_modify":"pms_page_lock_sub_module_modify",
    "admin_user_management_sub_module_modify":"pms_admin_user_management_sub_module_modify",
    "admin_roles_and_permission_view" : 'pms_admin_roles_and_permissions_sub_module_view'
   
}

export const permission_key_values_practice_dictionaries = {
    "practice_dictionaries_sub_module_add": "pms_practice_dictionaries_sub_module_add",
    "practice_dictionaries_sub_module_modify": "pms_practice_dictionaries_sub_module_modify",
}

export const permission_key_values_practice_user_management = {
    "user_management_modify": "pms_user_management_sub_module_add",
    "user_management_view": "pms_user_management_sub_module_view"
}

export const permission_key_values_super_Admin = {
    "customer_management_super_admin_add" : "pms_admin_customer_management_sub_module_add",
    "user_management_super_admin_add" : "pms_admin_user_management_sub_module_add",
    "roles_and_permissions_super_admin_modify" : "pms_admin_roles_and_permissions_sub_module_modify",
    "audit_logs_admin_view" : "pms_admin_audit_logs_module_view",
    "settings_admin_view" : "pms_admin_settings_module_view",
    "dasboard_admin_view" : "pms_admin_dashboard_module_view"
}


export const permission_key_values_batch_status = {
    pms_batch_status_sub_module_view: "pms_batch_status_sub_module_view"
}
