import i18n from "../../../../utilities/i18n";

const TableHeadData = [
    {
        "name": "checkbox",
        "type": "checkbox",
        "width": "check_box",
        "sort": false,
        "value": false,
    },
    {
        "name": "Patient Id",
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t('commons.patient'),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t('statementManagement.releaseTracker.claims'),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": "Balance Due",
        "type": "currency",
        "width": "small",
        "sort": false,
    },
]



export const StatementManagementTableBodyData = [
    [
        {
            "name": "checked",
            "type": "checkbox",
            "width": "check_box",
            "sort": false,
            "value": false,
            "align": "center",
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "string",
            "clickable": false,
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_full_name",
            "value": "",
            "type": "string",
            "clickable": false,
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "claims",
            "value": "",
            "type": "multiple_chip",
            "clickable": false,
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "total_balance_due",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
    ]
];

export const StatementManagementTableConfig = {
    "name": "statement-management-table",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": StatementManagementTableBodyData
};