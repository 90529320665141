export const LOGIN_SUPER_ADMIN_GROUP = "1";

export const ROUTE_NOTFOUND = "/404";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_SET_PASSWORD = "/set-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_VERIFY_EMAIL = "/verify-email";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_FORCE_RESET_PASSWORD = "/force-reset-passwod";

export const SEARCH_TEXT_COUNT = 3;

let sAdminStr = "/super";
export const LOGIN_ROUTE = "";

export const RESET_PASSWORD_DURATION = 90;

export const LOGIN_ROUTE_SUPER = sAdminStr;
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_MYPROFILE = "/my-profile"
export const ROUTE_SUPER_DICTIONARIES = "/dictionaries";
export const ROUTE_PRACTICE_DICTIONARIES = "/practice-dictionaries";
export const ROUTE_POLICY_TYPE = "/dictionaries/policy-type";
export const ROUTE_PATIENT_TYPE = "/dictionaries/patient-type";
export const ROUTE_LANGUAGE_TYPE = "/dictionaries/patient-language";
export const ROUTE_PRACTICE_LANGUAGE_TYPE = "/practice-dictionaries/patient-language";
export const ROUTE_RELATIONSHIP = "/dictionaries/relationship";
export const ROUTE_PAYER_TYPES = "/dictionaries/payer-type";
export const ROUTE_REFERRAL_SOURCES = "/dictionaries/referral-source";
export const ROUTE_PRACTICE_REFERRAL_SOURCES = "/practice-dictionaries/referral-source";
export const ROUTE_TYPE_OF_NOTES = "/dictionaries/type-of-notes";
export const ROUTE_PRACTICE_TYPES = "/dictionaries/practice-types";
export const ROUTE_FACILITY_TYPES = "/dictionaries/facility-types";
export const ROUTE_ASSIGNMENT_BENEFITS = "/dictionaries/assignment-benefits";
export const ROUTE_ACCIDENT_TYPES = "/dictionaries/accident-types";
export const ROUTE_CLAIM_STATUSES = "/dictionaries/claim-status";
export const ROUTE_PRACTICE_CLAIM_STATUSES = "/practice-dictionaries/claim-status";
export const ROUTE_CLAIM_SUB_STATUSES = "/dictionaries/claim-sub-status";
export const ROUTE_PRACTICE_CLAIM_SUB_STATUSES = "/practice-dictionaries/claim-sub-status";
export const ROUTE_DOCUMENTATION_METHODS = "/dictionaries/documentation-method";
export const ROUTE_DOCUMENT_TYPES = "/dictionaries/document-type";
export const ROUTE_PRACTICE_DOCUMENT_TYPES = "/practice-dictionaries/document-type";
export const ROUTE_MODIFIERS = "/dictionaries/modifiers";
export const ROUTE_SERVICE_EXCEPTIONS = "/dictionaries/service-exception";
export const ROUTE_PRACTICE_SERVICE_EXCEPTIONS = "/practice-dictionaries/service-exception";
export const ROUTE_POS_CODES = "/dictionaries/pos-codes";
export const ROUTE_TRANSPORT_REASONS = "/dictionaries/trasport-reason";
export const ROUTE_TRACKING_STATUS_TYPES = "/dictionaries/tracking-status-type";
export const ROUTE_TRACKING_STATUS_SOURCES =
  "/dictionaries/tracking-status-source";
export const ROUTE_APPOINTMENT_STATUSES = "/dictionaries/appointment-status";
export const ROUTE_PRACTICE_APPOINTMENT_STATUSES = "/practice-dictionaries/appointment-status";
export const ROUTE_APPOINTMENT_TYPES = "/dictionaries/appointment-type";
export const ROUTE_PRACTICE_APPOINTMENT_TYPES = "/practice-dictionaries/appointment-type";
export const ROUTE_FINANCIAL_CLASSESS = "/dictionaries/financial-class";
export const ROUTE_PRACTICE_FINANCIAL_CLASSESS = "/practice-dictionaries/financial-class";
export const ROUTE_PAYER_CLASSESS = "/dictionaries/payer-class";
export const ROUTE_PRACTICE_PAYER_CLASSESS = "/practice-dictionaries/payer-class";
export const ROUTE_REVENUE_CODE = "/dictionaries/revenue-code";
export const ROUTE_REMITTANCE_REMARK_CODE =
  "/dictionaries/remittance-remark-code";
export const ROUTE_PRACTICE_REMITTANCE_REMARK_CODE =
  "/practice-dictionaries/remittance-remark-code";
export const ROUTE_ADJUSTMENT_REASON_CODE =
  "/dictionaries/adjustment-reason-code";
export const ROUTE_PRACTICE_ADJUSTMENT_REASON_CODE =
  "/practice-dictionaries/adjustment-reason-code";
export const ROUTE_CLAIM_FREQUENCY = "/dictionaries/claim-frequency";
export const ROUTE_SERVICE_TYPES = "/dictionaries/service-types";

export const ROUTE_INSURANCE_COMPANIES = "/dictionaries/insurance-companies";
export const ROUTE_INSURANCE_COMPANIES_ADD =
  "/dictionaries/insurance-companies-add";
export const ROUTE_INSURANCE_COMPANIES_EDIT =
  "/dictionaries/insurance-companies-edit";

export const ROUTE_USER_INSURANCE_COMPANIES = "/user/insurance-companies";
export const ROUTE_USER_INSURANCE_COMPANIES_ADD = "/user/insurance-companies-add";
export const ROUTE_USER_INSURANCE_COMPANIES_EDIT = "/user/insurance-companies-edit";

export const ROUTE_TAXONOMIES = "/dictionaries/taxonomies";
export const ROUTE_CPT_CODES = "/dictionaries/cpt-code";
export const ROUTE_PRACTICE_CPT_CODES = "/practice-dictionaries/cpt-code";
export const ROUTE_ICD10 = "/dictionaries/icd10";
export const ROUTE_PRACTICE_ICD10 = "/practice-dictionaries/icd10";
export const ROUTE_DELAY_REASON_CODE = "/dictionaries/delay-reason-code";
export const ROUTE_PAYMENT_ADJUSTMENT_TYPE = "/dictionaries/payment-adjustment-type";
export const ROUTE_PAYMENT_SOURCE = "/dictionaries/payment-source";

export const ROUTE_ASSOCIATE_USERS = "/billi-admin/associate-user";
export const ROUTE_PRACTICE_ASSOCIATE_USERS = "/practice-user/associate-user";
export const ROUTE_PRACTICE_ASSOCIATE_USERS_ADD = "/practice-user/associate-user-add";
export const ROUTE_ASSOCIATE_USERS_ADD = "/user/associate-user-add";
export const ROUTE_ASSOCIATE_USERS_EDIT = "/user/associate-user-edit";
export const ROUTE_PAGE_LOCKS = "/user/page-locks";
export const ROUTE_ROLES_AND_PERMISSIONS = "/user/roles-and-permissions";
export const ROUTE_ADMIN_ROLES_AND_PERMISSIONS = "/billi-admin/roles-permission";
export const ROUTE_CUSTOMER_PROFILE = "/billi-admin/customer-profile";
export const ROUTE_PRACTICES = "/user/practices";
export const ROUTE_PRACTICES_ADD = "/user/practices-add";
export const ROUTE_PRACTICES_EDIT = "/user/practices-edit";
export const ROUTE_REFERRING_PROVIDER = "/user/referring-provider";
export const ROUTE_REFERRING_PROVIDER_ADD = "/user/referring-provider-add";
export const ROUTE_REFERRING_PROVIDER_EDIT = "/user/referring-provider-edit";
export const ROUTE_FACILITIES = "/user/facilitiesList";
export const ROUTE_FACILITIES_ADD = "/user/facilities-add";
export const ROUTE_FACILITIES_EDIT = "/user/facilities-edit";
export const ROUTE_PROVIDER_MANAGEMENT = "/user/provider-management";
export const ROUTE_PROVIDER_ADD = "/user/provider-add";
export const ROUTE_PROVIDER_EDIT = "/user/provider-edit";
export const ROUTE_FEE_SCHEDULE_MANAGEMENT = "/user/fee-schedule-management";
export const ROUTE_STANDARD_FEE_SCHEDULE_MANAGEMENT =
  "/user/custom-fee-schedule-management";
export const ROUTE_CODE_MANAGEMENT = "/user/code-management";
export const ROUTE_REFERRING_PROVIDER_GROUP =
  "/dictionaries/referring-provider-group";
export const ROUTE_PRACTICE_REFERRING_PROVIDER_GROUP =
  "/practice-dictionaries/referring-provider-group";
export const ROUTE_PATIENTS_LIST = "/patient/patients";
export const ROUTE_PATIENT_ADD = "/patient/add-patient";
export const ROUTE_RUN_ELIGIBILITY = "/patient/run-eligibility";
export const ROUTE_PATIENT_ACCOUNTING = "/patient/patient-accounting";
export const ROUTE_STATEMENT_MANAGEMENT = "/patient/statement-management";
export const ROUTE_PAYMENT_PLAN = "/patient/payment-plan";
export const ROUTE_ACTIVE_PAYMENT_PLAN = "/patient/active-payment-plan";
export const ROUTE_PATIENT_LETTERS = "/patient/patient-letters";
export const ROUTE_PATIENTS_LEDGER = "/patient/ledger";
export const ROUTE_PATIENTS_LEDGER_LIST = "/patient/ledger-list";
export const ROUTE_SEARCH_CLAIMS = "/claims/search-claims";
export const ROUTE_SEARCH_CLAIMS_BETA = "/claims/search-claims-1";
export const ROUTE_CLAIMS_FILTER = "/claims/claims-filter";
export const ROUTE_AR_CLAIMS = "/claims/ar-claims";
export const ROUTE_AR_PAPER_CLAIMS = "/claims/ar-paper-claims";
export const ROUTE_POST_PAYMENTS = "/payment/post_payments";
export const ROUTE_VIEW_PAYMENTS = "/payment/view_payments";
export const ROUTE_VIEW_RECEIPTS = "/payment/view_receipts";
export const ROUTE_POST_PAYMENT_BETA = "/payment/post-payment-1";
export const ROUTE_VIEW_PAYMENT_BETA = "/payment/view-payment-1";
export const ROUTE_CSVTEMPLATES = "/csvtemplates";
export const ROUTE_ERA_POSTING = "/era_posting";

export const ROUTE_INBOX = "/inbox-list";
export const ROUTE_INBOX_MESSAGES = "/inbox-messages";
export const ROUTE_SEND_INBOX = "/send-inbox-messages-list";
export const ROUTE_SEND_INBOX_MESSAGES = "/send-inbox-messages";
export const ROUTE_DRAFT_INBOX = "/draft-inbox-messages-list";
export const ROUTE_DRAFT_INBOX_MESSAGES = "/draft-inbox-messages";
export const ROUTE_COMPOSE_MESSAGES = "/compose-messages";
export const ROUTE_REPLY_MESSAGES = "/reply-message";
export const ROUTE_TEMPLATE = "/templates-list";
export const ROUTE_ADD_TEMPLATE = "/add-template";

export const ROUTE_MY_TASKS = "/my-tasks/add";
export const ROUTE_MY_TASKS_EDIT = "/my-tasks/edit";
export const ROUTE_MY_TASKS_LIST = "/my-tasks/list";

export const ROUTE_INTERFACE_SEARCH = "/interface/interface-search";
export const ROUTE_INTERFACE_GRID = "/interface/interface_grid";
export const ROUTE_PROVIDER_NAME_MATCH = "/interface/provider_name_matching";
export const ROUTE_PAYER_NAME_MATCH = "/interface/payer_name_matching";
export const ROUTE_FACILITY_NAME_MATCH = "/interface/facility-name-matching";
export const ROUTE_TEST_CODE_SETUP = "/interface/test_code_setup";

export const ROUTE_USER_ACTION_LOGS = "/logs";
export const ROUTE_CLAIM_ACTION_LOGS = "/logs/claim";
export const ROUTE_BACKGROUND_TASK_LOGS = "/claims/batch-status";

export const ROUTE_USER_REPORTS_BUILDER = "/reports/builder";
export const ROUTE_USER_REPORTS_CHARGES_DEBIT_REPORT = "/reports/chargedebit";
export const ROUTE_USER_REPORTS_PAYMENT_ADJUSTMENTS_REPORT = "/reports/paymentadjustment";
export const ROUTE_USER_REPORTS_360DEGREE_REPORT = "/reports/360degree";
export const ROUTE_USER_REPORTS_OPENAR_REPORT = "/reports/openarreport";
export const ROUTE_USER_REPORTS_TRANSACTION_REPORT = "/reports/transaction";
export const ROUTE_USER_REPORTS_CASHFLOW_REPORT = "/reports/cashflow";
export const ROUTE_USER_REPORTS_WATERFALL_REPORT = "/reports/waterfall";
export const ROUTE_USER_REPORTS_VIEWER = "/reports/viewer";
export const ROUTE_USER_REPORTS_BUILDER_NEW = "/reports/builder/new";
export const ROUTE_USER_REPORTS_BUILDER_NEW_FROM_TEMP =
  "/reports/builder/new/edit";
export const ROUTE_USER_REPORTS_BUILDER_EDIT = "/reports/builder/edit";

export const DISTANCE_UNIT = "Miles";
export const RANDOM_COLORS = [
  "#ff6e23",
  "#5b2c0d",
  "#90d7f5",
  "#2a7ff6",
  "#673ed8",
  "#740f5d",
  "#f10b15",
  "#be5a26",
  "#7d8286",
  "#e65745",
  "#958c24",
  "#cf9e47",
];

export const  CLAIM_FREQUENCY_ORIGINAL_CLAIM_ID = 1;

export const PAGING_END_INDEX = 10;
export const DEFAULT_PAGING_SIZE = 20;
export const DELAY_REASON_CODE_CSV_NAME = "delay_reason_code.csv";
export const REVENUE_CODE_CSV_NAME = "revenue_code.csv";
export const REMITTANCE_REMARK_CODE_CSV_NAME = "remittance_remark_code.csv";
export const ADJUSTMENT_REASON_CODE_CSV_NAME = "adjustment_reason_code.csv";
export const PAYMENT_ADJUSTMENT_TYPE_CSV_NAME = "payment_adjustment_type.csv";
export const MODIFIERS_CSV_NAME = "modifiers.csv";
export const POS_CSV_NAME = "pos_code.csv";
export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
export const DEFAULT_DATE_FORMAT_TEXT = "mm/dd/yyyy";

export const TAB1 = "tab1";
export const TAB2 = "tab2";
export const TAB3 = "tab3";
export const TAB4 = "tab4";
export const TAB5 = "tab5";
export const TAB6 = "tab6";
export const TAB7 = "tab7";

export const TIN_TYPES = [
  { id: "1", name: "EIN" },
  { id: "2", name: "SSN" },
];
export const ACTIVE_TYPES = [
  { id: "1", name: "All" },
  { id: "2", name: "Active" },
  { id: "3", name: "Inactive" },
];
export const ACTIVE_TYPES_ALL_ID = "1";
export const ACTIVE_TYPES_ACTIVE_ID = "2";
export const ACTIVE_TYPES_INACTIVE_ID = "3";

export const GEO_API_KEY = window.getEnv('REACT_APP_GEO_API_KEY');

export const DEFAULTCLAIMFREQUENCY = "original claim";
export const DEFAULTRELATION = "self";

export const AVATAR_BG_COLOR = "#007bff";

export const NPI_SEARCH_URL =
  "https://npiregistry.cms.hhs.gov/api/?version=2.1&limit=200";
export const NPI_SEARCH_PATH = "super-admin/api-call/";
export const NPI_SEARCH_INDIVIDUAL_CODE = "NPI-1";
export const NPI_SEARCH_ORGANIZATION_CODE = "NPI-2";

export const INSURANCE_ACTIVE = 1;
export const INSURANCE_EXPIRED = 2;

export const INSURANCE_PRIORITY_PRIMARY_VALUE = "1";
export const INSURANCE_PRIORITY_PRIMARY_TEXT = "Primary Insurance";

export const INSURANCE_PRIORITY_SECONDARY_VALUE = "2";
export const INSURANCE_PRIORITY_SECONDARY_TEXT = "Secondary Insurance";

export const INSURANCE_PRIORITY_TERTIARY_VALUE = "3";
export const INSURANCE_PRIORITY_TERTIARY_TEXT = "Tertiary Insurance";

export const INSURANCE_PRIORITIES_ARRAY = [
  {
    id: INSURANCE_PRIORITY_PRIMARY_VALUE,
    name: INSURANCE_PRIORITY_PRIMARY_TEXT,
  },
  {
    id: INSURANCE_PRIORITY_SECONDARY_VALUE,
    name: INSURANCE_PRIORITY_SECONDARY_TEXT,
  },
  {
    id: INSURANCE_PRIORITY_TERTIARY_VALUE,
    name: INSURANCE_PRIORITY_TERTIARY_TEXT,
  },
];

export const PATIENT_TYPE_SELF_PAY = "self-pay patient";

export const DEFAULT_ASSIGNMENT_OF_BENEFITS = "Signed HCFA form on file.";

export const DEFAULT_ACCIDENT = "not related to accident";

export const DEFAULTSERVICELOCATION = "default";

export const POST_PAYMENT_TYPE_PATIENT_ID = 1; // Patient Payment
export const POST_PAYMENT_TYPE_INSURANCE_ID = 2; // Insurance Payment
export const PAYEMNT_MODES_CREDITCARD_ID = 3; // Credit Card
export const PAYMENT_MODE_CASH_ID = 1; // cash

export const CURRENCY_SYMBOL = "$ ";
export const NUMBER_OF_DECIMALS = 2;

export const CLAIMDATEOFSERVICERANGE = [
  { id: 1, name: "Custom date range" },
  { id: 2, name: "Today" },
  { id: 3, name: "Yesterday" },
  { id: 4, name: "This week" },
  { id: 5, name: "This month" },
  { id: 6, name: "This quarter" },
  { id: 7, name: "Last week" },
  { id: 8, name: "Last month" },
  { id: 9, name: "Last quarter" },
  { id: 10, name: "This year" },
  { id: 11, name: "Last year" },
  { id: 12, name: "Last 7 days" },
  { id: 13, name: "Last 30 days" },
  { id: 14, name: "Last 60 days" },
  { id: 15, name: "Last 90 days" },
  { id: 16, name: "Last 6 months" },
  { id: 17, name: "Last 12 months" },
];

export const ERASTATUS = [
  {id: 1, name: 'Unapplied'},
  {id: 2, name: 'Applied'},
  {id: 3, name: 'Partially Applied'},
]

export const DATE_FILTER_IDS = {
  custom: 1,
  today: 2,
  yesterday: 3,
  thisWeek: 4,
  thisMonth: 5,
  thisQuarter: 6,
  lastWeek: 7,
  lastMonth: 8,
  lastQuarter: 9,
  thisYear: 10,
  lastYear: 11,
  last7Days: 12,
  last30Days: 13,
  last60Days: 14,
  last90Days: 15,
  last6Months: 16,
  last12Months: 17,
};

export const MAX_OPEN_TABS_CLAIMS = 1;
export const MAX_OPEN_TABS_PATIENTS = 5;
export const MAX_OPEN_TABS_PAYMENTS = 1;

export const SSN_VALID_LENGTH = 9;
export const SSN_VALID_LENGTH_SMALL = 4;
export const DRUD_CODE_LENGTH = 11;

export const DAYORMONTH = [
  { id: 1, name: "Days" },
  { id: 2, name: "Months" },
];

export const DEFAULT_ADJUSTMENT_VALUE = 0;

export const DEFAULT_COUNTRY = "United States";

export const CLAIM_INS_VALIDATION_PRIMARY_IDS = [1, 9];
export const CLAIM_INS_VALIDATION_SECONDARY_IDS = [2, 10];
export const CLAIM_INS_VALIDATION_TERTIARY_IDS = [3, 11];
// 1:Send Electronic Claim to Primary, 2:Send Electronic Claim to Secondary, 3:Send Electronic Claim to Tertiary
// 9: Send Paper Claim to Primary,     10:Send Paper Claim to Secondary,    11:Send Paper Claim to Tertiary
export const PATIENT_MANDATE_LIST = [
  "Last Name*",
  "First Name*",
  "Gender*",
  "Date of birth*",
  "Patient type*",
  "Street1*",
  "City*",
  "State*",
  "ZIP*",
  "Country*",
  "Home Phone*",
  "Guarantor Last Name*",
  "Guarantor First Name*",
  "Guarantor Relationship*",
  "Guarantor Street1*",
  "Guarantor City*",
  "Guarantor State*",
  "Guarantor ZIP*",
  "Guarantor Country*",
  "Guarantor Home Phone*",
  "Emergency Last Name*",
  "Emergency First Name*",
  "Emergency Relationship*",
  "Emergency Street1*",
  "Emergency City*",
  "Emergency State*",
  "Emergency ZIP*",
  "Emergency Country*",
  "Emergency Home Phone*",
  "Primary Insurance Name*",
  "Primary Insurance Policy ID*",
  "Primary effective date*",
  "Primary Subscriber Relationship*",
  "Primary Subscriber Last name*",
  "Primary Subscriber first name*",
  "Primary Subscriber gender*",
  "Primary Subscriber Date of birth*",
  "Primary Subscriber Street1*",
  "Primary Subscriber City*",
  "Primary Subscriber State*",
  "Primary Subscriber Zip*",
  "Primary Subscriber Country*",
  "Secondary Insurance Name*",
  "Secondary Insurance Policy ID*",
  "Secondary effective date*",
  "Secondary Subscriber Relationship*",
  "Secondary Subscriber Last name*",
  "Secondary Subscriber first name*",
  "Secondary Subscriber gender*",
  "Secondary Subscriber Date of birth*",
  "Secondary Subscriber Street1*",
  "Secondary Subscriber City*",
  "Secondary Subscriber State*",
  "Secondary Subscriber Zip*",
  "Secondary Subscriber Country*",
  "Tertiary Insurance Name*",
  "Tertiary Insurance Policy ID*",
  "Tertiary effective date*",
  "Tertiary Subscriber Relationship*",
  "Tertiary Subscriber Last name*",
  "Tertiary Subscriber first name*",
  "Tertiary Subscriber gender*",
  "Tertiary Subscriber Date of birth*",
  "Tertiary Subscriber Street1*",
  "Tertiary Subscriber City*",
  "Tertiary Subscriber State*",
  "Tertiary Subscriber Zip*",
  "Tertiary Subscriber Country*",
];

export const CLAIM_POS_IDS_VALIDATION = [21, 51, 61];

export const DEFAULT_SELECTED_PATIENTS_TAB = [{ "pk": "last10", "is_opend": true }];
export const DEFAULT_SELECTED_PATIENTS_TAB_PK = ["last10"];

export const ALLOWED_FILE_EXTENSIONS = [
  'pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif',
  'xls', 'xlsx', 'csv', 'txt', 'heif', 'heic'
]

export const FEATURE_FLAG = window.getEnv('FEATURE_FLAG');


export const PATIENT_STATEMENT_VENDORS = [{ id: 1, name: "DMA" }];

export const PATIENT_STATEMENT_STATUSES = [
  { id: 1, name: "Released from System" },
  { id: 2, name: "Acknowledgement Received" },
  { id: 3, name: "Vendor Error" },
  { id: 4, name: "Address Mismatch" },
  { id: 5, name: "System Error" },
];
export const BATCH_STATUS = [
  { id: 0, label: 'All' },
  { id: 5, label: 'Error' },
  { id: 3, label: 'Success' }
]
