import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Box, Button, Divider, Grid, IconButton, Input, InputAdornment, InputLabel, Stack, Typography } from "@mui/material";
import { CommonDateFormat, currencyFormat } from '../../../../utilities/commonUtilities';
import RemitanceCodeMultiSelect from '../../RemitanceCodeMultiSelect';
import { Tooltip as MUITooltip } from '@mui/material';
import './style.css'
import SelectInput from '../../../commons/input/SelectInput';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import i18n from '../../../../utilities/i18n';
const labelStyle = {
    color: "var(--Grey-G600, #757575)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: '5px'
};

const typographyStyle = {
    overflow: "hidden",
    color: "var(--Grey-G900, #212121)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    textOverflow: "ellipsis",
    fontFamily: "Lato",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
};
// A single Row Component for better memoization
export const Row = React.memo(({ id, key, index, item, adjustment_1_codes_list, maxResults, setMaxResults, onChange, prTotal,
    handleSearchAdjustmentReason_1_code, selectOnTab, handleDeleteAdjustment, typeHeadAdjustment1Ref, handleOPtions, removeProcedure,
    onHandleAdjustment_1_code, handleAdjustmentAmountChange, typeHeadAdjustment2Ref, adjustment_2_codes_list, onBlurApply, reversePayment,
    handleSearchAdjustmentReason_2_code, onHandleAdjustment_2_code, onHandleMultiSelectSearchForRemarkCodes, claimSubStatusList,
    claimStatusList, procedureError, updatePayment
}) => {
    Row.displayName = 'Row'
    return (
        <tr key={key}
            style={index % 2 == 0 ?
                { backgroundColor: '#fff', border: '1px solid #E0E0E0' } :
                { backgroundColor: '#F5F5F5', border: '1px solid #E0E0E0' }}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={12} style={{
                    marginTop: 5
                }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '12%'
                            }}
                        >
                            <span>DOS</span>
                            <span>
                                {item?.dos_from
                                    ? CommonDateFormat(item?.dos_from)

                                    : ""}
                            </span>
                        </Stack>
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '12%'
                            }}
                        >
                            <span>Procedure</span>
                            <span>
                                {item?.procedure}
                            </span>
                        </Stack>
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '12%'
                            }}
                        >
                            <span>Charges</span>
                            <span>
                                {currencyFormat(item?.charges)}
                            </span>
                        </Stack>
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '15%'
                            }}
                        >
                            <span>Reciepts</span>
                            <span>
                                {currencyFormat(item?.receipts)}
                            </span>
                        </Stack>
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '15%'
                            }}
                        >
                            <span>Opening Balance</span>
                            <span>
                                {currencyFormat(item?.opening_balance)}
                            </span>
                        </Stack>
                        <Stack
                            key={item.id ?? index}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '20%'
                            }}
                        >
                            <InputLabel style={labelStyle}>Allowed Amount</InputLabel>
                            <div style={{ border: `0.5px solid #ced4da`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                <Input
                                    type='text'
                                    className='table-input-era-posting'
                                    name='allowed_amount'
                                    value={item?.allowed_amount ?? "0.00"}
                                    onChange={(e) => onChange(id, e.target.value, 'allowed_amount')}
                                    onBlur={() => onBlurApply(id, "allowed_amount", index)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    endAdornment={
                                        procedureError[id]?.allowed_amount ?
                                            <InputAdornment position="end">
                                                <MUITooltip title={procedureError[id]?.allowed_amount} placement="top">
                                                    <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                </MUITooltip>
                                            </InputAdornment>
                                            : null
                                    }
                                />
                            </div>
                        </Stack>
                        <Stack
                            key={item.id ?? index}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            sx={{
                                padding: '10px',
                                width: '20%'
                            }}
                        >
                            <InputLabel style={labelStyle}>Paid Amount</InputLabel>
                            <div style={{ border: `0.5px solid #ced4da`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                <Input
                                    type='text'
                                    name='paid_amount'
                                    className='table-input-era-posting'
                                    value={item?.paid_amount ?? "0.00"}
                                    onChange={(e) => onChange(id, e.target.value, 'paid_amount')}
                                    onBlur={() => onBlurApply(id, "paid_amount", index)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    endAdornment={
                                        procedureError[id]?.paid_amount ?
                                            <InputAdornment position="end">
                                                <MUITooltip title={procedureError[id]?.paid} placement="top">
                                                    <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                </MUITooltip>
                                            </InputAdornment>
                                            : null
                                    }
                                />
                            </div>
                        </Stack>
                    </Stack>
                    <Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Divider orientation="horizontal" variant="middle" style={{ margin: 0 }} />
            <Grid item xs={12}>
                <Stack
                    key={key}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            paddingTop: '10px',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            paddingBottom: '10px',
                            width: '20%'
                        }}
                    >
                        <InputLabel style={labelStyle}>Adjustment-1 Codes</InputLabel>
                        <Stack direction="column" spacing={.5}>
                            {item?.adjustment_code1_data?.map((item1, index2) => {
                                return (
                                    <Stack key={index2} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px', width: '180px' }}>
                                        <div style={{ borderRadius: 3, display: 'flex', height: '18px' }}>
                                            <span style={{ marginLeft: 2 }}>{item1.label ?? item1.name.substring(0, 7)}</span>
                                            <IconButton aria-label="delete" onClick={() => handleDeleteAdjustment(item1, index2, index, id, "adjustment_code1_data")} style={{ marginLeft: 'auto', marginRight: 2 }}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </div>
                                    </Stack>
                                )
                            })}
                        </Stack>
                        <Stack key={key} sx={{ width: '100%', marginTop: item?.adjustment_code1_data?.length ? '8px' : '0 !important' }}>
                            <div style={{ width: '100%' }}>
                                <AsyncTypeahead
                                    ref={(el) => typeHeadAdjustment1Ref.current[index] = el}
                                    id="search_adjustment_1_codes"
                                    labelKey="name"
                                    minLength={1}
                                    maxResults={maxResults}
                                    onPaginate={() =>
                                        setMaxResults(maxResults + 5)
                                    }
                                    paginationText={"Load More"}
                                    options={adjustment_1_codes_list}
                                    onSearch={(query) => handleSearchAdjustmentReason_1_code(query)}
                                    name="selectClaim"
                                    onInputChange={(e) =>
                                        handleOPtions(
                                            e,
                                            "adjustment_1_codes_list"
                                        )
                                    }
                                    onKeyDown={(e) => selectOnTab(e)}
                                    onChange={(e) => onHandleAdjustment_1_code(e, id, index)}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "100px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    renderMenuItemChildren={(option) => {
                                        let description;
                                        let isDescriptionTooLong;
                                        description = option.name;
                                        isDescriptionTooLong =
                                            description.length > 30;
                                        return (
                                            <div>
                                                <OverlayTrigger
                                                    placement={"right"}
                                                    delay={{ show: 250 }}
                                                    overlay={
                                                        <Tooltip id={`tooltip-right`}>
                                                            {option.name}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div onClick={(e) => onHandleAdjustment_1_code(e, id, index)}>
                                                        <strong>{`${option.label}    `}</strong>
                                                        <span>
                                                            {isDescriptionTooLong
                                                                ? `- ${description.substring(
                                                                    0,
                                                                    30
                                                                )}...`
                                                                : `- ${description}`}
                                                        </span>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </Stack>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%'
                        }}
                    >
                        <InputLabel style={labelStyle}>Adjustment-1 Amt</InputLabel>
                        <Stack sx={{ width: '100%' }}>
                            <Stack direction="column" spacing={.5} alignItems="flex-start" >
                                {item?.adjustment_code1_data?.map((adjstmntCode1) => {
                                    return (
                                        <Stack key={adjstmntCode1.id} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px' }}>
                                            <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                    {adjstmntCode1?.label}
                                                </Tooltip>
                                            } >
                                                <Input
                                                    sx={{
                                                        padding: '3px',
                                                        fontSize: '1rem',
                                                        height: '18px',
                                                        width: '100%',
                                                    }}
                                                    name='adjustment_amount1'
                                                    id="standard-adornment-amount"
                                                    type="text"
                                                    inputMode="numeric"
                                                    pattern="[0-9]*"
                                                    step="any"
                                                    value={adjstmntCode1?.amount}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    onChange={(e) => handleAdjustmentAmountChange(e, adjstmntCode1, id, 'adjustment_code1_data')}
                                                    onBlur={() => onBlurApply(id, "adjustment_amount1", index)}
                                                    endAdornment={
                                                        procedureError[id]?.adjustment_amount1?.[adjstmntCode1.id]?.id === adjstmntCode1.id ?
                                                            <InputAdornment position="end">
                                                                <MUITooltip title={procedureError[id]?.adjustment_amount1?.[adjstmntCode1.id]?.message} placement="top">
                                                                    <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                </MUITooltip>
                                                            </InputAdornment>
                                                            : null
                                                    }
                                                />
                                            </OverlayTrigger>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%'
                        }}
                    >
                        <InputLabel style={labelStyle}>Adjustment-2 Codes</InputLabel>
                        <Stack direction="column" spacing={.5}>
                            {item?.adjustment_code2_data?.map((item1, index2) => {
                                return (
                                    <Stack key={index2} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px', width: '180px' }}>
                                        <div style={{ borderRadius: 3, display: 'flex', height: '18px' }}>
                                            <span style={{ marginLeft: 2 }}>{item1.label ?? item1.name.substring(0, 7)}</span>
                                            <IconButton aria-label="delete" onClick={() => handleDeleteAdjustment(item1, index2, index, id, "adjustment_code2_data")} style={{ marginLeft: 'auto', marginRight: 2 }}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </div>
                                    </Stack>
                                )
                            })}
                        </Stack>
                        <Stack key={key} sx={{ width: '100%', marginTop: item?.adjustment_code2_data?.length ? '8px' : '0 !important' }}>
                            <div style={{ width: '100%' }}>
                                <AsyncTypeahead
                                    ref={(el) => typeHeadAdjustment2Ref.current[index] = el}
                                    id="search_adjustment_2_codes"
                                    labelKey="name"
                                    minLength={1}
                                    maxHeight={"150px"}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    options={adjustment_2_codes_list}
                                    onSearch={(query) => handleSearchAdjustmentReason_2_code(query)}
                                    name="selectClaim"
                                    onKeyDown={(e) => selectOnTab(e)}
                                    onInputChange={(e) => handleOPtions(e, "adjustment_2_codes_list")}
                                    onChange={(e) => onHandleAdjustment_2_code(e, id, index)}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "100px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                    renderMenuItemChildren={(option) => {
                                        let description;
                                        let isDescriptionTooLong;
                                        description = option.name;
                                        isDescriptionTooLong =
                                            description.length > 30;
                                        return (
                                            <div>
                                                <OverlayTrigger
                                                    placement={"right"}
                                                    delay={{ show: 250 }}
                                                    overlay={
                                                        <Tooltip id={`tooltip-right`}>
                                                            {option.name}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div onClick={(e) => onHandleAdjustment_2_code(e, id, index)}>
                                                        <strong>{`${option.label}  `}</strong>
                                                        <span>
                                                            {isDescriptionTooLong
                                                                ? `- ${description.substring(
                                                                    0,
                                                                    30
                                                                )}...`
                                                                : `- ${description}`}
                                                        </span>
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </Stack>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%'
                        }}
                    >
                        <InputLabel style={labelStyle}>Adjustment-2 Amt</InputLabel>
                        <Stack sx={{ width: '100%' }}>
                            <Stack direction="column" spacing={.5} alignItems="flex-start" >
                                {item?.adjustment_code2_data?.map((adjstmntCode2) => {
                                    return (
                                        <Stack key={adjstmntCode2.id} sx={{ border: `0.5px solid #ced4da`, borderRadius: '3px', padding: '2px' }}>
                                            <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                    {adjstmntCode2?.label}
                                                </Tooltip>
                                            } >
                                                <Input
                                                    sx={{
                                                        padding: '3px',
                                                        fontSize: '1rem',
                                                        height: '18px',
                                                        width: '100%',
                                                    }}
                                                    id="standard-adornment-amount"
                                                    type="text"
                                                    inputMode="numeric"
                                                    name='adjustment_amount2'
                                                    pattern="[0-9]*"
                                                    step="any"
                                                    value={adjstmntCode2?.amount}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    onChange={(e) => handleAdjustmentAmountChange(e, adjstmntCode2, id, 'adjustment_code2_data')}
                                                    onBlur={() => onBlurApply(id, "adjustment_amount2", index)}
                                                    endAdornment={
                                                        procedureError[id]?.adjustment_amount2?.[adjstmntCode2.id]?.id === adjstmntCode2.id ?
                                                            <InputAdornment position="end">
                                                                <MUITooltip title={procedureError[id]?.adjustment_amount2?.[adjstmntCode2.id]?.message} placement="top">
                                                                    <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                </MUITooltip>
                                                            </InputAdornment>
                                                            : null
                                                    }
                                                />
                                            </OverlayTrigger>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%'
                        }}
                    >
                        <InputLabel style={labelStyle}>Copay</InputLabel>
                        <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                            <Input
                                id="copay"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                step="any"
                                sx={{ border: 'none !important' }}
                                className='table-input-era-posting'
                                name='copay'
                                disabled={item?.save_procedure_id && item?.pay_procedure_status === 0}
                                onChange={(e) => onChange(id, e.target.value, 'copay')}
                                onBlur={() => onBlurApply(id, "copay", index)}
                                value={item?.copay}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </div>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            paddingRight: '10px',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingBottom: '10px',
                            width: '20%' // 20% width for the last two stacks
                        }}
                    >
                        <InputLabel style={labelStyle}>Co-Insurance</InputLabel>
                        <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                            <Input
                                id="coInsurance"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                step="any"
                                sx={{ border: 'none !important' }}
                                className='table-input-era-posting'
                                name="co_insurance"
                                value={item?.co_insurance}
                                disabled={item?.save_procedure_id && item?.pay_procedure_status === 0}
                                onChange={(e) => onChange(id, e.target.value, 'co_insurance')}
                                onBlur={() => onBlurApply(id, "co_insurance", index)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </div>
                    </Stack>
                </Stack>
            </Grid>
            <Divider orientation="horizontal" variant="middle" style={{ margin: 0 }} />
            <Grid item xs={12} style={{ marginBottom: 5 }}>
                <Stack
                    key={key}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            paddingTop: '10px',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            paddingBottom: '10px',
                            width: '20%'
                        }}
                    >
                        {/* DEDUCTIBLE */}
                        <InputLabel style={labelStyle}>Deductible</InputLabel>
                        <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                            <Input
                                id="deductible"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                step="any"
                                sx={{ border: 'none !important' }}
                                className='table-input-era-posting'
                                name="deductibles"
                                disabled={item.save_procedure_id && item.pay_procedure_status === 0}
                                onChange={(e) => onChange(id, e.target.value, 'deductibles')}
                                onBlur={() => onBlurApply(id, "deductibles", index)}
                                value={item?.deductibles}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </div>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%' // Conditional width based on index
                        }}
                    >
                        <InputLabel style={labelStyle}>PR Total</InputLabel>
                        <Box style={{ flexGrow: 1 }} />
                        <Typography style={typographyStyle}> {currencyFormat(
                            prTotal
                                ? prTotal
                                : Number(item?.deductibles ?? 0) +
                                Number(item?.co_insurance ?? 0) +
                                Number(item?.copay ?? 0)
                        )}</Typography>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%' // 20% width for the last two stacks
                        }}
                    >
                        {/* REMARK-CODES */}
                        <InputLabel style={labelStyle}>Remark Codes</InputLabel>
                        <div style={{ marginTop: '4px' }}>
                            <RemitanceCodeMultiSelect
                                name={"labelRemarkCodes"}
                                width={150}
                                onValueChange={(e) => onHandleMultiSelectSearchForRemarkCodes(e, id)}
                                selectedValues={item?.selectedRemarkCodes || []}
                            />
                        </div>
                    </Stack>
                    <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '15%' // Conditional width based on index
                        }}
                    >
                        <InputLabel style={labelStyle}>Closing Balance</InputLabel>
                        <Box style={{ flexGrow: 1 }} />
                        <Typography style={typographyStyle}>{item.closing_balance
                            ? currencyFormat(
                                item.closing_balance
                            )
                            : currencyFormat(0)}</Typography>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%' // 20% width for the last two stacks
                        }}
                    >

                        <InputLabel style={labelStyle}>Responsibility</InputLabel>
                        <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                            <SelectInput background={'transparent !important'}
                                data={item.responsibility_list}
                                value={item.responsibility_type}
                                onValueChange={(e) => onChange(id, e.target.value, 'responsibility_type')}
                            />
                        </div>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%' // Conditional width based on index
                        }}
                    >
                        <InputLabel style={labelStyle}>Claim Status</InputLabel>
                        <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                            <SelectInput
                                data={claimStatusList?.filter(ele => ele.priorities?.includes(item?.responsibility_type))}
                                name="claim_status_id"
                                onValueChange={(e) => onChange(id, e.target.value, 'claim_status_id')}
                                value={item?.claim_status_id}
                            />
                        </div>
                    </Stack>
                    <Stack
                        key={key}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                            padding: '10px',
                            width: '20%' // Conditional width based on index
                        }}
                    >
                        <InputLabel style={labelStyle}>Claim Sub Status</InputLabel>
                        <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                            <SelectInput
                                data={claimSubStatusList?.filter(ele => ele.parent_claim_status_ids?.includes(item?.claim_status_id) || item.id == item?.claim_sub_status_id)}
                                name="claim_sub_status_id"
                                value={item?.claim_sub_status_id}
                                onValueChange={(e) => onChange(id, e.target.value, 'claim_sub_status_id')}
                            />
                        </div>

                    </Stack>
                </Stack>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <Button style={{ marginRight: 10 }} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => removeProcedure(id)}>
                                Delete
                            </Button>
                        </Stack>
                        <Stack
                            key={key}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            {item.save_procedure_id && item.pay_procedure_status === 0 && updatePayment && (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250 }}
                                    overlay={
                                        <Tooltip
                                            id={`tooltip-right`}
                                            style={{
                                                opacity: 0.4,
                                            }}
                                        >
                                            {i18n.t(
                                                "payments.post_payments.insurancePayment.table2.reverse"
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        style={{ marginRight: 10 }}
                                        variant="outlined"
                                        disabled={
                                            item.entry_reversed
                                                ? true
                                                : false
                                        }
                                        startIcon={<SettingsBackupRestoreOutlinedIcon />}
                                        onClick={() => reversePayment(id, index)}
                                    >
                                        Reverse Payment
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </tr>
    );
});