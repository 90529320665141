// React Imports
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux Reducer
import { updateFieldValues } from './StatementManagmentRedux/statementManagementSlice';

// utils
import { commonTableBody } from '../../../utilities/commonUtilities';


/**
 * Custom hook to manage loading bar state based on API state
 * @param {Object} apiState - The current API state
 * @param {Function} setShowLoadingBar - Function to set the loading bar visibility
 */
export const useLoadingBarEffect = (apiState, setShowLoadingBar) => {
    useEffect(() => {
        setShowLoadingBar(apiState.isLoading);
    }, [apiState.isLoading]);
};


/**
 * Custom hook to handle API errors and show notifications
 * @param {string} parentState - Key of the parent state
 * @param {Object} apiState - The current API state
 * @param {Function} showNotifyWindow - Function to show notification window
 */
export const useApiNotifyHandler = (parentState, apiState, showNotifyWindow) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Show notification based on API state
        if (apiState.isError && apiState.message) {
            showNotifyWindow(
                "show",
                "error",
                apiState.message,
                4000,
                apiState.notifyPrimaryText
            );

            // Reset API state after showing notification
            dispatch(updateFieldValues({
                state: parentState,
                field: 'apiState',
                value: {
                    isLoading: false,
                    isError: false,
                    isSuccess: false,
                    message: "",
                    notifyPrimaryText: ""
                }
            }));
        } else if (apiState.isSuccess && apiState.message) {
            showNotifyWindow(
                "show",
                "success",
                apiState.message,
                4000,
                apiState.notifyPrimaryText
            );
            // Reset API state after showing notification
            dispatch(updateFieldValues({
                state: parentState,
                field: 'apiState',
                value: {
                    isLoading: false,
                    isError: false,
                    isSuccess: false,
                    message: "",
                    notifyPrimaryText: ""
                }
            }));
        }

    }, [apiState]);
};


/**
 * Custom hook to handle Table Update when redux table state changes
 * @param {Array} tableData - The current table data from Redux state
 * @param {Array} StatementReleaseTrackerTableBodyData - Default table body data
 * @param {Object} StatementReleaseTrackerTableConfig - Table configuration object
 * @returns {number} tableKey - A key to force re-render of the table component
 */
export const useTableDataUpdate = (tableData, tableBody, tableConfig) => {
    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        if (Array.isArray(tableData) && tableData.length > 0) {
            // Convert the received data into the common table body format
            const rowArray = commonTableBody(
                tableData,
                tableBody[0]
            );
            tableConfig.tableBodyData = rowArray;
        } else {
            // Reset to the default table body data if no data is available
            tableConfig.tableBodyData = tableBody;
        }
        setTableKey(prevKey => prevKey + 1);
    }, [tableData]);

    return tableKey;
};
