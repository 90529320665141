import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";
import Label from '../../Label';

const AsyncTypeInput = (props) => {
  const typeHeadRef = useRef();
  const alignTooltip = props.alignTooltip ? props.alignTooltip : "right";
  // increase drop-down list on click ('Load)
  const [maxResults, setMaxResults] = useState(5);

  // invoked on option selection, to set the selected item
  const handleTypeheadOnchange = (e, name) => {
    props.onValueChange(e, name);
  };

  /* invoked on input change to set the label
    if there's input present, then add the label else remove.
    when there's no input the component will render the placeholder instead of label.
  */
  const onAsyncInputChange = () => {


    /**
     * Commenting out Paste restriction as it cause automation boat not to work due to speed insert or paste insert
     * there is a ticket already to have a permanent solution to get this automatically selected on paste - BILL-3577
     * 
     * Originally the paste was restricted by the ticket - BILL-1850.
     */

    // Disable past event as onPast the value will not be selected but it will reflect in UI making the user think that it is indeed selected!
    // if (e.nativeEvent.inputType === "insertFromPaste" && typeof inputValue === "string") {
    //   // Clear the input to remove the value after a paste event
    //   if (typeHeadRef.current && typeof typeHeadRef.current.clear === 'function') {
    //     typeHeadRef.current.clear();
    //   }
    // }
  };

  function selectOnTab(e) {
    if (e.key === "Tab") {
      const tab = document.querySelector("a.dropdown-item.active");
      if (tab) document.querySelector("a.dropdown-item.active").click();
    }
  }

  const onClear = ()=> {
    typeHeadRef.current.getInput().blur(); 
  }

  useEffect(()=> {
if(props.clearFlag > 0) onClear();
  },[props.clearFlag])

  return (
    <>
      <Label label={props.label} />
    <div className="typeHeadStyle">
      <AsyncTypeahead
        className={props.class_name ? props.class_name : ""}
        id={props.id ? props.id : "basic-typeahead-single"}
        labelKey={props.labelKey}
        minLength={props.minLength}
        options={props.options}
          // placeholder={props.placeholder}
        onSearch={props.onSearch}
        name={props.name}
        dropup={props.dropup}
        maxResults={maxResults}
        onPaginate={() => setMaxResults(maxResults + 5)}
        paginationText={"Load More"}
        onChange={handleTypeheadOnchange}
        onBlur={(e) => { props.onBlur ? props.onBlur(e) : null }}
        inputProps={props.inputProps}
        selected={props.selected}
        ref={typeHeadRef}
        clearButton={true}
        onKeyDown={(e) => selectOnTab(e)}
        onInputChange={() => onAsyncInputChange()}
        isLoading={props.isLoading || false}
        onMenuToggle={() => {
          // This function uses the onMenuToggle callback to trigger an API call that retrieves description and label details when the input box is clicked. 
          // Without this, the user would need to manually modify the input to trigger the API call. 
          // The API call is executed only if the isMenuToggleCallbackNeeded prop is passed and an item has already been selected during the initial render.
          if (!props.isMenuToggleCallbackNeeded || !props.selected.length) return;
          const isItemAlreadyInList = props.options.some(item => item.name === props.selected[0].name);
          if (!isItemAlreadyInList) {
            props.onSearch(props.selected[0].name);
          }
        }}
        renderMenuItemChildren={(option) => {
          let description;
          let isDescriptionTooLong;
          if (Object.prototype.hasOwnProperty.call(option, "description")) {
            description = option.description;
            isDescriptionTooLong = description.length > 40;
          }
          return (
            <div>
              {description ? (
                <OverlayTrigger
                  placement={alignTooltip}
                  delay={{ show: 250 }}
                  data-testid="tooltip-right"
                  overlay={
                    <Tooltip id={`tooltip-right`} data-testid={`tooltip-right`} style={{ opacity: 0.4 }}>
                      {description}
                    </Tooltip>
                  }
                >
                  <div
                    onClick={() =>
                      handleTypeheadOnchange([option], props.name)
                    }
                    value={option.label}
                    data-testid={`tooltip-right-${option.name}`}
                  >
                    {option.name && <strong>{`${option.name}  `}</strong>}
                    <span>
                      {isDescriptionTooLong
                        ? `- ${description.substring(0, 40)}...`
                        : `- ${description}`}
                    </span>
                  </div>
                </OverlayTrigger>
              ) : (
                <div
                    onClick={() => handleTypeheadOnchange([option], props.name)}
                  value={option.label}
                >
                  {option.name && <strong>{`${option.name}  `}</strong>}
                </div>
              )}
            </div>
          );
        }}
        />
      </div>
    </>
  );
};

export default AsyncTypeInput;
