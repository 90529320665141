
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import i18n from "../../../../utilities/i18n";
import { Button, Divider, Grid, Input, InputAdornment, InputLabel, Stack } from "@mui/material";
import { CommonDateFormat, currencyFormat } from "../../../../utilities/commonUtilities";
import SelectInput from "../../../commons/input/SelectInput";
import { useDispatch } from "react-redux";
import { onHandleApplyPaient, resetPatientProcedureDetails } from "../StateManagement/paymentSlice";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
const labelStyle = {
  color: "var(--Grey-G600, #757575)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginBottom: '5px'
};

//eslint-disable-next-line
const typographyStyle = {
  overflow: "hidden",
  color: "var(--Grey-G900, #212121)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  textOverflow: "ellipsis",
  fontFamily: "Lato",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
};
const PatientProcedureList = () => {
  const dispatch = useDispatch();
  let { procedureData, responsibilityData, claimStatusList, claimSubStatusList } = useSelector((state) => state.paymentData);

  const onHandleApply = useCallback((e, id, field) => {
    let value = "";
    if (field == "apply_payment" || field === "apply_adjustments") {
      value = String(e.target.value).trim()
        .replace(/[^0-9.]/g, '')  // Remove all non-numeric and non-period characters
        .replace(/(\..*?)\./g, '$1')  // Remove all extra decimal points
        .replace(/(\.\d{2})\d*/g, '$1')  // Allow only two digits after the first decimal point
        .substring(0, 9);  // Limit the length to 9 characters
    } else if (field === "responsibility_type") {
      value = parseInt(e.target.value);
    } else {
      value = e.target.value;
    }
    dispatch(onHandleApplyPaient({ value: value, id: id, field: field }))
  }, [])

  //eslint-disable-next-line
  const resetPaymentDetails = (id, index,item) => {
    dispatch(resetPatientProcedureDetails({id:id,index:index,item}));
  }

  return (
    <div>
      <div style={{ marginTop: 15 }}>
        <table className='table' id='overflow_auto'>
          <tbody>
            {
              (Object.keys(procedureData).length === 0) && (
                <tr>
                  <td align="center" colSpan={20}>
                    {i18n.t("commons.noRecords")}{" "}
                  </td>
                </tr>
              )
            }
            {Object.keys(procedureData).length > 0 &&
              Object.entries(procedureData).map(([id, item], index) => (
                <tr
                  key={id}
                  style={
                    index % 2 === 0
                      ? { backgroundColor: '#fff', border: '1px solid #E0E0E0' }
                      : { backgroundColor: '#F5F5F5', border: '1px solid #E0E0E0' }
                  }
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} style={{
                      marginTop: 5
                    }}>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>DOS</span>
                          <span>
                            {item?.dos_from
                              ? CommonDateFormat(item?.dos_from)

                              : ""}
                          </span>
                        </Stack>
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>Procedure</span>
                          <span>
                            {item?.procedure}
                          </span>
                        </Stack>
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>Charges</span>
                          <span>
                            {currencyFormat(item?.charges)}
                          </span>
                        </Stack>
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>Reciepts</span>
                          <span>
                            {currencyFormat(item?.receipts)}
                          </span>
                        </Stack>
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>Opening Balance</span>
                          <span>
                            {currencyFormat(item?.opening_balance)}
                          </span>
                        </Stack>
                        <Stack
                          key={item.id ?? index}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                          sx={{
                            padding: '10px',
                            width: '20%'
                          }}
                        >
                          <span>Closing Balance</span>
                          <span>
                            {currencyFormat(item?.closing_balance)}
                          </span>
                        </Stack>
                      </Stack>
                      <Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider orientation="horizontal" variant="middle" style={{ margin: 0 }} />
                  <Grid item xs={12}>
                    <Stack
                      key={item.id ?? index}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                    >

                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                          padding: '10px',
                          width: '20%'
                        }}
                      >
                        <InputLabel style={labelStyle}>Apply Payment</InputLabel>
                        <div style={{ border: `0.5px solid #ced4da`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                          <Input
                            type='text'
                            className='table-input-era-posting'
                            name='apply_payment'
                            value={item?.apply_payment ?? "0.00"}
                            onChange={(e) =>
                              onHandleApply(
                                e,
                                id,
                                "apply_payment"
                              )
                            }
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                        </div>
                      </Stack>
                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                          padding: '10px',
                          width: '20%'
                        }}
                      >
                        <InputLabel style={labelStyle}>Apply Adjustments</InputLabel>
                        <div style={{ border: `0.5px solid #ced4da`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                          <Input
                            type='text'
                            name='apply_adjustments'
                            className='table-input-era-posting'
                            value={item?.apply_adjustments ?? "0.00"}
                            onChange={(e) =>
                              onHandleApply(
                                e,
                                id,
                                "apply_adjustments"
                              )
                            }
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                        </div>
                      </Stack>
                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                          padding: '10px',
                          width: '20%'
                        }}
                      >
                        <InputLabel style={labelStyle}>Responsibility</InputLabel>
                        <SelectInput
                          data={responsibilityData}
                          value={item.responsibility_type ? item.responsibility_type
                            : 1
                          }
                          onValueChange={(e) =>
                            onHandleApply(
                              e,
                              id,
                              "responsibility_type"
                            )
                          }
                        />
                      </Stack>
                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                          padding: '10px',
                          width: '20%'
                        }}
                      >
                        <InputLabel style={labelStyle}>Claim Status</InputLabel>
                        <SelectInput
                          name='claim_status_id'
                          data={claimStatusList?.filter(data => data.priorities?.includes(item?.responsibility_type))}
                          value={item.claim_status_id}
                          onValueChange={(e) =>
                            onHandleApply(
                              e,
                              id,
                              "claim_status_id"
                            )
                          }
                        />
                      </Stack>
                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{
                          padding: '10px',
                          width: '20%'
                        }}
                      >
                        <InputLabel style={labelStyle}>Claim Sub Status</InputLabel>
                        <SelectInput
                          name='claim_sub_status_id'
                          data={
                            item?.claim_status_id
                              ? claimSubStatusList?.filter(subStatus =>
                                subStatus.parent_claim_status_ids?.includes(item?.claim_status_id)
                              )
                              : []
                          }
                          value={item.claim_sub_status_id}
                          onValueChange={(e) =>
                            onHandleApply(
                              e,
                              id,
                              "claim_sub_status_id"
                            )
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Stack
                        key={item.id ?? index}
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Button
                          style={{ marginRight: 10,marginBottom:10 }}
                          variant="outlined"
                          disabled={
                            item.entry_reversed
                              ? true
                              : false
                          }
                          startIcon={<RestartAltIcon />}
                          onClick={() => resetPaymentDetails(id, index,item)}
                        >
                          Reset
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}


export default PatientProcedureList;