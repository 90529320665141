import React, { useEffect, useState, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Third-party libraries
import { Form } from 'react-bootstrap';
import { format } from 'date-fns';

// Utilities and constants
import i18n from '../../../../utilities/i18n';
import { PAGING_END_INDEX, PATIENT_STATEMENT_STATUSES, PATIENT_STATEMENT_VENDORS } from '../../../../utilities/staticConfigs';

// Redux actions and selectors
import { updateFieldValues, getStatementReleaseTrackerData, resetStatementReleaseTrackerData, initialStatementReleaseTrackerState } from '../StatementManagmentRedux/statementManagementSlice';

// Services
import service from "../Services";

// Components
import PatientDropDownSearch from '../../patients/patientDropDownSearch';
import PatientsAdvancedSearch from '../../patients/PatientsAdvancedSearch';
import RightSidePanel from '../../../commons/RightSidePanel/RightSidePanel';
import CalendarInput from '../../../commons/input/CalendarInput';
import SelectInput from '../../../commons/input/SelectInput';
import TextInput from "../../../commons/input/input";
import AsyncTypeInput from '../../../commons/input/AsyncTypeHead/AsyncTypeInput';
import CommonButton from '../../../commons/Buttons';
import Table from '../../../commons/Table/Table';
import Pagination from '../../../commons/pagination';

// Table Configuration
import { StatementReleaseTrackerTableBodyData, StatementReleaseTrackerTableConfig } from './StatementReleaseTrackerTable';
import { useApiNotifyHandler, useLoadingBarEffect, useTableDataUpdate } from '../hooks';


// Memoized Table Component
// Caching the report table to avoid un-necessary table render
const MemoizedTable = memo(({ tableObject, dropDownFunction }) => {
    return (
        <div className="table-responsive">
            <Table
                tableObject={tableObject}
                dropDownFunction={dropDownFunction}
            />
        </div>
    );
});
MemoizedTable.displayName = 'MemoizedTable';



/**
 * StatementReleaseTracker Component
 * @component
 * @param {Object} props - The component props
 * @param {Function} props.showNotifyWindow - Function to show notification messages
 * @param {Function} props.setShowLoadingBar - Function to control the visibility of the loading bar
 * @param {number} props.practicePK - The primary key of the current practice
 * @returns {JSX.Element} The rendered StatementReleaseTracker component
 */
function StatementReleaseTracker({
    showNotifyWindow,
    setShowLoadingBar,
    practicePK
}) {
    const dispatch = useDispatch();
    const {
        filters,
        tableData,
        pagination,
        storedFiltersForPagination,
        apiState
    } = useSelector(state => state.statementMgData.statementReleaseTrackerTabData);
    const [claimOptionsList, setClaimOptionsList] = useState([]);
    const tableKey = useTableDataUpdate(tableData, StatementReleaseTrackerTableBodyData, StatementReleaseTrackerTableConfig);


    useLoadingBarEffect(apiState, setShowLoadingBar);
    useApiNotifyHandler('statementReleaseTrackerTabData', apiState, showNotifyWindow);


    /**
     * Fetches the statement release tracker data from the API when the component mounts.
     * This effect is triggered only if the current table data is empty.
     * @function useEffect
     * @returns {void}
     */
    useEffect(() => {
        // Check if the table data is empty before dispatching the API call
        if (Array.isArray(tableData) && tableData.length === 0) {
            dispatch(getStatementReleaseTrackerData({
                filters,
                practicePK,
                pagination
            }));
        }
    }, []);

    /**
     * Handles changes to input fields in the Statement Release Tracker filters.
     * @param {string} field - The name of the field being updated.
     * @param {*} value - The new value for the field. Can be of any type, depending on the input.
     * 
     * @description
     * It handles special formatting for date fields, converting them to the 'YYYY-MM-DD' format.
     * For all other fields, it uses the value as-is.
     * @dispatches
     * This function dispatches the `updateFieldValues` action to update the Redux store.
     */
    const handleInputChange = (field, value) => {
        let serializedValue = value;

        if (field === "release_date_from" && value instanceof Date) {
            serializedValue = (value && !isNaN(value)) ? format(value, 'yyyy-MM-dd') : null;
        }

        if (field === "release_date_to" && value instanceof Date) {
            serializedValue = (value && !isNaN(value)) ? format(value, 'yyyy-MM-dd') : null;
        }

        if (field === "claim_pk") {
            dispatch(updateFieldValues({
                state: 'statementReleaseTrackerTabData',
                field: 'filters',
                value: {
                    ...filters,
                    [field]: serializedValue[0]?.id || null,
                    selected_claim: serializedValue
                }
            }));

            return;
        }

        dispatch(updateFieldValues({
            state: 'statementReleaseTrackerTabData',
            field: 'filters',
            value: { ...filters, [field]: serializedValue }
        }));
    };



    /**
     * @description Handle Patient Async Type Head Input Change
     * @param {string} field to update 
     * @param {Array || boolean} value 
     * @returns 
     */
    const handlePatientChange = (field, value) => {
        const updatedValue = Array.isArray(value) && value.length ? value : [];
        const patient_pk = Array.isArray(value) && value[0]?.id ? value[0]?.id : "";

        const newFilters = { ...filters };

        switch (field) {
            case 'patientSelected':
                newFilters["patient_selected"] = updatedValue;
                newFilters["patient_pk"] = patient_pk
                newFilters["patientAdvSearchData"] = updatedValue;
                break;
            case 'patientAdvSearchData':
                newFilters["patientAdvSearchData"] = value;
                break;
            case 'patientRemoved':
                if (value === true) {
                    newFilters["patient_selected"] = [];
                    newFilters["patient_pk"] = "";
                    newFilters["patientAdvSearchData"] = [];
                }
                break;
            default:
                return; // Exit if field does not match any case
        }

        if (field !== 'patientRemoved') {
            dispatch(updateFieldValues({
                state: 'statementReleaseTrackerTabData',
                field: 'filters',
                value: newFilters
            }));
        } else if (field === 'patientRemoved' && value === true) {
            dispatch(updateFieldValues({
                state: 'statementReleaseTrackerTabData',
                field: 'filters',
                value: {
                    ...newFilters,
                    patient_pk: "",
                    patient_selected: [],
                    patientAdvSearchData: null
                }
            }));
        }
    };



    /**
     * Handles the search functionality for the claim dropdown.
     * This method is triggered when the user types in the claim search input.
     * It uses a debounced version of the API call to prevent excessive requests.
     * 
     * @async
     * @param {string} query - The search query entered by the user
     * @throws {Error} Throws an error if the API call fails or returns unexpected data
     */
    const handleClaimDropDownSearch = async (query) => {
        try {
            setShowLoadingBar(true);
            const response = await service.debouncedListClaimsDropDown(query, filters.patient_pk ?? "", practicePK);

            if (response.status === 200 && Array.isArray(response.data)) {
                setClaimOptionsList(response.data);
            } else {
                throw new Error("An error occurred while getting the claims list.");
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            console.warn(error);
        } finally {
            setShowLoadingBar(false);
        }
    }



    /**
     * Resets all filters, pagination, and table data to their initial states,
     * then fetches fresh data from the API.
     */
    const handleReset = () => {
        dispatch(resetStatementReleaseTrackerData())
        setTimeout(() => {
            dispatch(getStatementReleaseTrackerData({
                filters: initialStatementReleaseTrackerState.filters,
                practicePK,
                pagination: initialStatementReleaseTrackerState.pagination,
            }));
        }, 100);
        setClaimOptionsList([]);
        showNotifyWindow('show', 'success', 'Filters and data have been reset.');
    };



    /**
     * Handles the action when the user clicks the "Run Report" button.
     * This function dispatches an action to fetch the statement release tracker data
     * @dispatches
     * - getStatementReleaseTrackerData: An action to fetch the statement release tracker data
     *   with the current filters, practice primary key, and page number.
     */
    const handleRunReport = () => {
        dispatch(getStatementReleaseTrackerData({
            filters,
            practicePK,
            pagination: initialStatementReleaseTrackerState.pagination
        }));
        // Storing the current filters as a back-up so for the pagination this state can be used
        dispatch(updateFieldValues({
            state: 'statementReleaseTrackerTabData',
            field: 'storedFiltersForPagination',
            value: filters
        }));
    };



    /**
     * Handles the action when the user clicks the previous page button.
     * It dispatches actions to fetch the previous page of data and update the pagination state.
     */
    const onPagePrevious = () => {
        const previousPage = pagination.startIndex + 1 - PAGING_END_INDEX;
        let newStartIndex = pagination.startIndex - PAGING_END_INDEX;
        let newEndIndex = pagination.endIndex - PAGING_END_INDEX;

        dispatch(getStatementReleaseTrackerData({
            filters: storedFiltersForPagination,
            practicePK,
            pagination: {
                ...pagination,
                page: previousPage,
                startIndex: newStartIndex,
                endIndex: newEndIndex
            }
        }));
    };

    /**
     * Handles the action when the user clicks a specific page number.
     * It dispatches an action to fetch the data for the selected page and updates pagination accordingly.
     * @param {Event} e - The event object from the click action
     */
    const onPageUp = (e) => {
        const page = Number(e.target.id);

        dispatch(getStatementReleaseTrackerData({
            filters: storedFiltersForPagination,
            practicePK,
            pagination: {
                ...pagination,
                page,
            }
        }));
    };

    /**
     * Handles the action when the user clicks the next page button.
     * It dispatches actions to fetch the next page of data and update the pagination state.
     */
    const onPageNext = () => {
        const nextPage = pagination.startIndex + 1 + PAGING_END_INDEX;
        if (nextPage <= pagination.total_pages) {
            let newStartIndex = pagination.startIndex + PAGING_END_INDEX;
            let newEndIndex = Math.min(pagination.endIndex + PAGING_END_INDEX, pagination.total_pages);

            dispatch(getStatementReleaseTrackerData({
                filters: storedFiltersForPagination,
                practicePK,
                pagination: {
                    ...pagination,
                    page: nextPage,
                    startIndex: newStartIndex,
                    endIndex: newEndIndex
                }
            }));
        }
    };



    /**
     * @description Get Signed URL of the Batch file and trigger download
     * @param {Number} id table row id
     */
    const downloadBatchFile = useCallback(async (id) => {
        const batch_id = tableData?.find(item => item.id === id)?.batch_id;
        if (!batch_id) {
            showNotifyWindow("show", "error", "Batch ID not found for the given ID.");
            console.warn("Batch ID not found for the given ID:", id);
            return; // Exit early if batch_id is not found
        }

        try {
            setShowLoadingBar(true);
            const response = await service.getStatementBatchFileUrl({
                practice_pk: practicePK,
                batch_id: batch_id
            });

            const { data, status } = response;

            if (status === 200 && data?.url) {
                window.open(data.url, '_blank');
            } else {
                console.error("Failed to retrieve the batch file URL:", response);
                showNotifyWindow("show", "error", "Failed to retrieve the batch file URL.");
            }
        } catch (error) {
            console.error("Error fetching batch file URL:", error);
            showNotifyWindow("show", "error", "An error occurred while fetching the batch file.");
        } finally {
            setShowLoadingBar(false);
        }
    }, [tableData]);


    const handleDownloadReport = () => {
        dispatch(getStatementReleaseTrackerData({
            filters,
            practicePK,
            pagination: initialStatementReleaseTrackerState.pagination,
            is_export: true
        }));
    }


    return (
        <>
            <Form autoComplete="off" className="searchBox pl-4 pb-5 pr-4">
                <div className="row padding-bottom10 ">
                    <PatientDropDownSearch
                        setPatientPK={(value) => handlePatientChange('patient_pk', value)}
                        patientSelected={filters.patient_selected}
                        setPatientSelected={(value) => handlePatientChange('patientSelected', value)}
                        patientAdvSearchData={filters.patientAdvSearchData}
                        setPatientRemoved={(value) => handlePatientChange('patientRemoved', value)}
                    />
                    <div className="col justify-right">
                        <div className="input-content-box padding-top22 justify-right">
                            <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                <PatientsAdvancedSearch
                                    setPatientPK={(value) => handlePatientChange('patient_pk', value)}
                                    setPatientSelected={(value) => handlePatientChange('patientSelected', value)}
                                    setPatientAdvSearchData={(value) => handlePatientChange('patientAdvSearchData', value)}
                                />
                            </RightSidePanel>
                        </div>
                    </div>
                </div>

                <div className="row padding-top12">
                    <div className="col-3">
                        <AsyncTypeInput
                            id="searchByClaimId"
                            labelKey="name"
                            minLength={0}
                            options={claimOptionsList}
                            onSearch={(e) => { handleClaimDropDownSearch(e) }}
                            name="selectClaim"
                            value={filters.selected_claim}
                            onValueChange={(e) => handleInputChange('claim_pk', e)}
                            selected={filters.selected_claim}
                            label={i18n.t('statementManagement.releaseTracker.searchClaim')}
                        />
                    </div>

                    <div className="col-3">
                        <CalendarInput
                            selected={filters.release_date_from ? new Date(filters.release_date_from) : null}
                            name="release_date_from"
                            id="batch_release_date_from"
                            maxDate={new Date()}
                            label={i18n.t('statementManagement.releaseTracker.releaseDateFrom')}
                            disableFuture={true}
                            onValueChange={(date) => handleInputChange('release_date_from', date)}
                        />
                    </div>

                    <div className="col-3">
                        <CalendarInput
                            selected={filters.release_date_to ? new Date(filters.release_date_to) : null}
                            name="release_date_to"
                            id="batch_release_date_to"
                            maxDate={new Date()}
                            minDate={filters.release_date_from ? new Date(filters.release_date_from) : null}
                            label={i18n.t('statementManagement.releaseTracker.releaseDateTo')}
                            disableFuture={true}
                            onValueChange={(date) => handleInputChange('release_date_to', date)}
                        />
                    </div>

                    <div className="col-3">
                        <SelectInput
                            data={PATIENT_STATEMENT_STATUSES}
                            label={i18n.t('statementManagement.releaseTracker.statementStatus')}
                            name="status"
                            id="statement_status"
                            value={filters.status}
                            onValueChange={(e) => handleInputChange('status', e.target.value)}
                        />
                    </div>

                    <div className="col-3">
                        <TextInput
                            type="text"
                            name="batch_id"
                            id="statement_release_batch_id"
                            label={i18n.t('statementManagement.releaseTracker.batchId')}
                            value={filters.batch_id}
                            onValueChange={(e) => handleInputChange('batch_id', e.target.value)}
                        />
                    </div>

                    <div className="col-3">
                        <SelectInput
                            data={PATIENT_STATEMENT_VENDORS}
                            label={i18n.t('statementManagement.practiceRules.statementVendor')}
                            name="statement_vendor"
                            id="statement_vendor"
                            value={filters.statement_vendor}
                            onValueChange={(e) => handleInputChange('statement_vendor', e.target.value)}
                        />
                    </div>

                    <div className="col-12 div-float-right">
                        <div className="div-float-right">
                            <span className="margin-right15" >
                                <CommonButton
                                    onClick={handleReset}
                                    variant="outlined"
                                    label={i18n.t("buttons.reset")}
                                />
                            </span>
                            <span className="margin-right15" >
                                <CommonButton
                                    icon="download"
                                    onClick={handleDownloadReport}
                                    variant="outlined"
                                    label={"Download Report"}
                                />
                            </span>
                            <CommonButton
                                onClick={handleRunReport}
                                variant="contained"
                                label={i18n.t("statementManagement.runStatement")}
                            />
                        </div>
                    </div>
                </div>
            </Form>

            <div className='mt-5'>
                <MemoizedTable
                    key={tableKey}
                    tableObject={StatementReleaseTrackerTableConfig}
                    dropDownFunction={downloadBatchFile}
                />

                <Pagination
                    totalPage={pagination.total_pages}
                    activePage={pagination.page}
                    startIndex={pagination.startIndex}
                    endIndex={pagination.endIndex}
                    onPagePrevious={onPagePrevious}
                    onPageUp={onPageUp}
                    onPageNext={onPageNext}
                />
            </div>
        </>
    )
}


StatementReleaseTracker.propTypes = {
    showNotifyWindow: PropTypes.func.isRequired,
    setShowLoadingBar: PropTypes.func.isRequired,
    practicePK: PropTypes.number.isRequired
};

export default StatementReleaseTracker