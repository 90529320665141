import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import AsyncTypeInput from "../../commons/input/AsyncTypeHead/AsyncTypeInput";
import i18n from "../../../utilities/i18n";
import { useDispatch } from "react-redux";
import { removeAllPaymentDetails, resetAlertMessage, resetNotifyWindow, resetPaymentDetails, showModalWindow, updateDate, updateValueChange, updatePayerDetails } from "./StateManagement/paymentSlice";
import { GetAdjustmentTypes, getClaimStatusAndSubStatus, getPaymentDetails, handleProcedureSubmit, postPaymentManually, searchPayerList } from "./StateManagement/asyncThunkAPI";
import Notify from "../../commons/notify";
import LoadingContext from "../../../container/loadingContext";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from "../../commons/input/input";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import { CREDIT_CARD_TYPES, INSURANCEPAYMENTMODES } from "../../../utilities/dictionaryConstants";
import SelectInput from "../../commons/input/SelectInput";
import { PAYEMNT_MODES_CREDITCARD_ID, ROUTE_VIEW_PAYMENTS } from "../../../utilities/staticConfigs";
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
import TextAreaInput from "../../commons/textarea/textarea";
import CommonButton from "../../commons/Buttons";
import { useHistory } from "react-router-dom";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import { currencyFormat } from "../../../utilities/commonUtilities";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import InsurancePaymentDetails from "./InsurancePaymentDetails";
import ClaimSearchComponent from "./ClaimSearchComponent";
import InsuranceClaimList from "./ClaimList/ClaimList";
import { getStorage } from "../../../utilities/browserStorage";
const InsurancePayment = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const { paymentPK } = useSelector((state) => state.paymentData.postPaymentDetails);
    const { payer, paymentDate, reference_check_number, amount, payment_mode, card_type, creditCard,
        level_adjustments, adjustment_type, note
    } = useSelector((state) => state.paymentData.insurancePaymentVariables);
    const { payersList, payerListLoading, notifyMessage, showNotification, statusTag, selectedPayer, claimList, saveTriggered,
        adjustmentTypes, paymentPostLoading, header, showPostAsCreditModal, showAlertMessage, alertMessage,clickedFromTable
    } = useSelector((state) => state.paymentData);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    /**
     * initial api calls
     */
    useEffect(() => {
        dispatch(GetAdjustmentTypes());
        dispatch(getClaimStatusAndSubStatus());
    }, [])

    /**
 * call api to get payment details and all initial apis 
 */
    useEffect(() => {
        if (clickedFromTable) {
            dispatch(getPaymentDetails({ pk: paymentPK, action: "get" }));
        }
    }, [clickedFromTable])

    /**
     * show all loading here 
     */
    useEffect(() => {
        setShowLoadingBar(payerListLoading);
    }, [payerListLoading])

    useEffect(() => {
        setShowLoadingBar(paymentPostLoading);
    }, [paymentPostLoading])

    useEffect(() => {
        if (showNotification && notifyMessage) {
            showNotifyWindow("show", statusTag, notifyMessage, 4000);
            dispatch(resetNotifyWindow())

            if (saveTriggered) {
                if (props.updatePayment) {
                    setTimeout(() => {
                        props.setSelectedEditPaymentPK("");
                        props.setActiveTab("viewPayments");
                        dispatch(removeAllPaymentDetails())
                    }, 500)
                } else window.location.reload();
            }
        }
    }, [showNotification]);

    //function to show error messages
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    /**
     * funtion inovke while user search a perticular payer from the search list is clicked
     * @param {*} e  
     */
    const handleSearchPayer = (e) => {
        dispatch(updatePayerDetails({ e: e, action: "post" }))
    }

    /**
     *funtion inovke while user search a perticular payer details 
     */
    const onSearchPayer = (searchValue) => {
        dispatch(searchPayerList({searchValue:searchValue,practicePK:practicePK}))
    }

    /**
     * while changing the date below function is ivoked
     * @param {*} e 
     * @param {*} name 
     */
    const onHandleDate = (e, name) => {
        if (name === "paymentDate") {
            dispatch(updateDate({value:e,name:name,key:"insurancePaymentVariables"}))
        }
    }

    /**
     * when other field value change
     * @param {*} e 
     */
    const onHandleChange = (e) => {
        if (e.target) {
            let name = e.target.name;
            let value =
                e.target.type === "checkbox" ? e.target.checked : e.target.value;
            dispatch(updateValueChange({ name: name, value: value,key:"insurancePaymentVariables" }))
        }
    }

    //on reset all details of insurance payment
    const onResetPayment = () => {
        dispatch(resetPaymentDetails())
    }

    /**
     * on postmanually button click to create the payment
     */
    const paymentPostManually = () => {
        dispatch(postPaymentManually('manually'))
    }

    /**
     * reset the modal pop up data
     */
    const resetForm = () => {
        dispatch(showModalWindow({ key: "showPostAsCreditModal", open: false, header: '' }))
    }

    /**
     * to reset all alert message
     */
    const onAlertOk = () => {
        dispatch(resetAlertMessage())
    }

    const cancelPayment = () => {
        dispatch(removeAllPaymentDetails())
        if (props.onClosePayment) {
            props.onClosePayment();
        } else {
            history.push(ROUTE_VIEW_PAYMENTS)
        }
    }

    const savePayment = () => {
        dispatch(handleProcedureSubmit())
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <div className={props.updatePayment ? "postPayment" : ""}>
                    <div className="">
                        <Form autoComplete="off">
                            {!paymentPK &&
                                <div className='insurancePayment'>
                                    <div className="row">
                                        <div className="col-3">
                                            <AsyncTypeInput
                                                labelKey="name"
                                                class_name={payer == "" ? "input-error" : ""}
                                                name="payer"
                                                minLength={1}
                                                placeholder={""}
                                                onValueChange={(e) => handleSearchPayer(e, "payer")}
                                                options={payersList}
                                                onSearch={onSearchPayer}
                                                id={"payer"}
                                                label={i18n.t(
                                                    "payments.post_payments.insurancePayment.payer"
                                                )}
                                                clearButton={true}
                                                selected={selectedPayer}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <CalendarInput
                                                name="paymentDate"
                                                maxDate={new Date()}
                                                required={true}
                                                selected={paymentDate}
                                                onValueChange={(e) => onHandleDate(e, "paymentDate")}
                                                label={i18n.t(
                                                    "payments.post_payments.insurancePayment.payment_date_EFT"
                                                )}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <TextInput
                                                    name="reference_check_number"
                                                    required={true}
                                                    value={reference_check_number}
                                                    onValueChange={onHandleChange}
                                                    label={i18n.t(
                                                        "payments.post_payments.insurancePayment.payment_reference"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <CurrencyInputs
                                                name="amount"
                                                value={amount}
                                                onValueChange={onHandleChange}
                                                label={i18n.t(
                                                    "payments.post_payments.insurancePayment.payment_amount"
                                                )}
                                                defaultClassName={
                                                    amount === "" ? "input-error" : ""
                                                }
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <SelectInput
                                                name="payment_mode"
                                                required={true}
                                                data={INSURANCEPAYMENTMODES}
                                                value={payment_mode}
                                                onValueChange={onHandleChange}
                                                label={i18n.t(
                                                    "payments.post_payments.insurancePayment.payment_mode"
                                                )}
                                            />
                                        </div>
                                        {payment_mode ===
                                            PAYEMNT_MODES_CREDITCARD_ID && (
                                                <div className="col-3">
                                                    <SelectInput
                                                        name="card_type"
                                                        required={true}
                                                        data={CREDIT_CARD_TYPES}
                                                        value={card_type}
                                                        onValueChange={onHandleChange}
                                                        label={i18n.t("payments.post_payments.credit_card")}
                                                        showCreditCardIcons={true}
                                                        className={
                                                            creditCard
                                                                ? "input-error"
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                            )}
                                        <div className="col-3 ">
                                            <div className='form-group'>
                                                <TextInput
                                                    name="level_adjustments"
                                                    value={level_adjustments}
                                                    onValueChange={onHandleChange}
                                                    label={i18n.t(
                                                        "payments.post_payments.insurancePayment.payment_level_adjustments"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <MaterialMultiselect name="adjustment_type" required={level_adjustments > 0 ? true : false} value={adjustment_type} onValueChange={onHandleChange}
                                                options={adjustmentTypes} label={i18n.t("payments.post_payments.insurancePayment.adjustment_type")}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextAreaInput
                                                name="note"
                                                value={note}
                                                onValueChange={onHandleChange}
                                                rows={3}
                                                label={i18n.t(
                                                    "payments.post_payments.insurancePayment.payment_note"
                                                )}
                                            />
                                        </div>
                                        <div className="col-6 justify-right padding-top70">
                                            <div className="padding-left15">
                                                <CommonButton
                                                    variant="outlined"
                                                    onClick={onResetPayment}
                                                    label={i18n.t("payments.post_payments.buttons.reset")}
                                                />
                                            </div>
                                            <div className="padding-left15">
                                                <CommonButton
                                                    variant="contained"
                                                    onClick={paymentPostManually}
                                                    label={i18n.t(
                                                        "payments.post_payments.insurancePayment.buttons.post_payment"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {paymentPK ? (
                                <>
                                    <InsurancePaymentDetails />
                                    <ClaimSearchComponent />
                                    {Object.keys(claimList).length > 0 &&
                                        <div className="col-6 justify-right padding-bottom20 padding-top20" style={{ marginLeft: 'auto' }}>
                                            <div className="padding-left15">
                                                <CommonButton
                                                    variant="outlined"
                                                    onClick={() => cancelPayment()}
                                                    label={i18n.t("payments.post_payments.buttons.cancel")}
                                                />
                                            </div>
                                            <div className="padding-left15">
                                                <CommonButton
                                                    variant="contained"
                                                    onClick={() => savePayment()}
                                                    label={i18n.t(
                                                        "payments.post_payments.buttons.save"
                                                    )}
                                                />
                                            </div>
                                        </div>}
                                    <InsuranceClaimList updatePayment={props.updatePayment} />
                                </>
                            ) : ""}
                        </Form>
                    </div>
                </div>
            </div>
            <div className="">
                <CustomizedDialogs
                    header={header}
                    showModal={showPostAsCreditModal}
                    setShowModalWindow={() => dispatch(showModalWindow({ key: "showPostAsCreditModal", open: false, header: '' }))}
                    resetForm={() => resetForm()}
                >
                    Payment Has Been Posted To Patient - John,Doe <br />
                    <br />
                    Posted Credit - {currencyFormat(100)}
                    <br />
                    Total Credits - {currencyFormat(150)}
                    <br />
                    Patient Due - {currencyFormat(150)}
                    <br />
                    Total Patient Balance - {currencyFormat(0)}
                </CustomizedDialogs>
            </div>
            <CustomizedSmallDialogs
                showModal={showAlertMessage}
                header={i18n.t("commons.alert")}
                alertOK={() => onAlertOk()}
                type="alert"
                setShowModalWindow={() => dispatch(resetAlertMessage())}
            >
                {alertMessage}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )
}

export default InsurancePayment;