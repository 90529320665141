// React and Hooks
import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import {
    Box,
    Card,
    CardContent,
    Typography,
} from '@mui/material';

// Custom Components
import Table from '../../../commons/Table/Table';

// Table Config
import { QueuedItemsTableBodyData, QueuedItemsTableConfig } from './QueuedItemsTable';

// Custom Hooks
import { useTableDataUpdate } from '../hooks';



/**
 * @component Component to display Queued Claims for Statement release
 * @param {object} queuedItems Object containing the response data
 */
const QueuedItemsDisplay = ({ queuedItems }) => {
    const tableKey = useTableDataUpdate(queuedItems?.updated_patients || [], QueuedItemsTableBodyData, QueuedItemsTableConfig);

    if (!queuedItems?.updated_patients?.length) {
        return (
            <Typography align="center" sx={{ p: 2 }}>
                No data available
            </Typography>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Card
                sx={{
                    mb: 3,
                    bgcolor: '#ffffff',
                    boxShadow: 3,
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                }}
            >
                <CardContent sx={{ textAlign: 'center', padding: 3 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Statement Release Queue Summary
                    </Typography>
                </CardContent>
            </Card>

            {/* Queued Items Table */}
            <Table
                key={tableKey}
                tableObject={QueuedItemsTableConfig}
            />
        </Box>
    );
};


// PropTypes validation
QueuedItemsDisplay.propTypes = {
    queuedItems: PropTypes.shape({
        updated_patients: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                custom_patient_id: PropTypes.number.isRequired,
                patient_pk: PropTypes.number.isRequired,
                patient_name: PropTypes.string.isRequired,
                claims: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        id: PropTypes.string.isRequired,
                    })
                ).isRequired,
            })
        ).isRequired,
        total_updated_claims: PropTypes.number.isRequired,
        total_updated_procedures: PropTypes.number.isRequired,
    }).isRequired,
};

export default QueuedItemsDisplay;
