import { useEffect } from "react";
import { useDispatch } from "react-redux";// update with the actual path
import { getPatientPaymentClaimDetails, getPatientPaymentData, GetPatientPaymentPlans, onSavePatientPayment } from "./StateManagement/asyncThunkAPI";
import { onChangePostPaymentType, onPatientDropDownSelectAction, removeAllPaymentDetails, resetNotifyWindow, setPatientPostDetails } from "./StateManagement/paymentSlice";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";

export const useAutoSavePayment = (postManually, autoPost, patientPK, paymentPK) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if ((postManually || autoPost) && patientPK) {
            if (!paymentPK) {
                dispatch(onSavePatientPayment(patientPK));
            }
            dispatch(getPatientPaymentClaimDetails({ pageSize: DEFAULT_PAGING_SIZE, page: 1, patientPK: patientPK }));
        }
    }, [autoPost, postManually]);
};


export const useFetchPatientPaymentPlans = (patientPK,patientSelected,patientAdvSearchData,selectPatient) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (patientPK) {
            dispatch(GetPatientPaymentPlans(patientPK));
            dispatch(onPatientDropDownSelectAction({
                patientSelected: patientSelected,
                patientAdvSearchData: patientAdvSearchData,
                selectPatient: selectPatient,
            }))
        }
    }, [patientPK]);
};


export const useSetPatientName = (selectPatient) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPatientPostDetails({
            value: selectPatient || "",
            name: "patientName"
        }));
        
        if(!selectPatient){
            dispatch(onPatientDropDownSelectAction({
                patientSelected: [],
                patientAdvSearchData: [],
                selectPatient: "",
            })) 
        }
    }, [selectPatient]);
};


export const useLoadingBar = (paymentPostLoading, setShowLoadingBar) => {
    useEffect(() => {
        setShowLoadingBar(paymentPostLoading);
    }, [paymentPostLoading]);
};

export const useNotify = (showNotification, notifyMessage, statusTag, showNotifyWindow, updatePayment, saveTriggered, props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (showNotification && notifyMessage) {
            showNotifyWindow("show", statusTag, notifyMessage, 4000);
            dispatch(resetNotifyWindow());

            if (saveTriggered) {
                if (updatePayment) {
                    setTimeout(() => {
                        props.setSelectedEditPaymentPK("");
                        props.setActiveTab("viewPayments");
                        dispatch(removeAllPaymentDetails())
                    }, 500)
                } else window.location.reload();
            }
        }
    }, [showNotification]);
};


export const useGetPaymentData = (paymentPK, updatePayment, setPatientPK, patientPaymentPatientPK, patientPaymentPostType) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (paymentPK) {
            dispatch(getPatientPaymentData({ paymentPK: paymentPK, updatePayment: updatePayment, action: "get" }))
            setPatientPK(patientPaymentPatientPK);
            if (patientPaymentPostType && updatePayment) {
                dispatch(onChangePostPaymentType(patientPaymentPostType === 1 ? "manually" : "auto"))
            }
        }
    }, [paymentPK])
}