import React from 'react';
import { styled } from '@mui/material/styles';
import Logo from './Logo';
import NavItems from './NavItems';
import './style.css'
import UserProfile from './UserProfile';

const HeaderContainer = styled('div')(() => ({
    width: '100%',
    height: '80px',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex:60,
    position:'fixed',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
}))

const LogoContainer = styled('div')(({theme}) => ({
    paddingLeft: '45px',
    [theme.breakpoints.between('sm','md')]:{
        display:'none'
    },
    [theme.breakpoints.down('sm')]:{
        display:'none'
    }
}))

const NavItemsContainer = styled('div')(({theme}) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft:40,
    paddingRight:40,
    [theme.breakpoints.between('sm','md')]:{
        paddingLeft:10,
    },
    [theme.breakpoints.down('sm')]:{
        paddingLeft:10,
    }
}));


const StickyAppBar = () => {

    return (
        <>
            <HeaderContainer>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <NavItemsContainer>
                    <NavItems />
                </NavItemsContainer>
                <UserProfile />
            </HeaderContainer>
        </>
    );
};

export default StickyAppBar;