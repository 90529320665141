import {
  NUMBER_OF_DECIMALS,
  ROUTE_DASHBOARD,
  ROUTE_INSURANCE_COMPANIES_EDIT,
  ROUTE_PATIENTS_LIST,
} from "./staticConfigs"
import i18n from "../utilities/i18n";
import { getStorage } from "./browserStorage";
import moment from "moment";
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { clear as clearAllIndexedDbData } from "idb-keyval";

export function nameFormat(FName, MName, LName) {
  // NAME FORMAT SHOULD BE Lname, Fname Mname
  let nameString = "";
  let commaVar = "";
  if (LName && LName.trim().length > 0) {
    nameString = LName.trim();
    commaVar = ", ";
  }
  if (FName && FName.trim().length > 0) {
    nameString += commaVar + FName.trim();
    commaVar = "";
  }
  if (MName && MName.trim().length > 0) {
    nameString += commaVar + " " + MName.trim();
  }
  return nameString;
}

export function ValidateEmail(email) {
  //validating email using regex for checking email format
  var mailformat =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email) {
    return (email.match(mailformat)) ? true : false;
  }
}

export function checkBrowserAppleSafari() {
  var sBrowser,
    sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser === "safari" ? true : false;
}


export function decimalConverter(value) {
  let decimalValue = Number(parseFloat(value)).toFixed(2);
  return decimalValue;
}

export function CommonDateFormat(date){
const formatdate=date +"T00:00:00";
const dated = new Date(formatdate);
const formattedDate = format(dated, 'MM/dd/yyyy', { locale: enUS });
return formattedDate;
}

export function CommonGridPhoneNumber(num) {
  let numArray = Array.from(String(num));
  let data = "";
  numArray.forEach((item, index) => {
    if (index === 0) {
      data += "+" + item;
    } else if (index === 1) {
      data += " (" + item;
    } else if (index === 4) {
      data += ") " + item;
    } else if (index === 7) {
      data += "-" + item;
    } else {
      data += item;
    }
  });
  return data;
}

export function CommonTinFormat(num) {
  let numArray = Array.from(String(num));
  let data = "";
  numArray.forEach((item, index) => {
    if (index === 2 && item !== "-") {
      data += "-" + item;
    } else {
      data += item;
    }
  });
  return data;
}

export function HandlingTIN(curValue, PrevValue) {
  let CharacterPresent = String(curValue).match(/[a-z]/gi);
  if (!CharacterPresent) {
    if (String(curValue).length > 2 && !String(curValue).includes("-")) {
    return curValue.slice(0, 2) + "-" + curValue.slice(2);
    } else {
      return curValue;
    }
  } else if (CharacterPresent.length > 0) {
    return PrevValue;
  }
}

export function removeTags(str) {
  if (str === null || str === "") return str;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, " ");
}

export function YMD_TO_MDY(dateStr, separator) {
  if (!dateStr || dateStr === null || dateStr === "") {
    return dateStr;
  } else {
    let dtArr = [];
    dtArr = dateStr.toString().substring(0, 10).split("-");
    let sep = separator && separator.length ? separator : "/";
    return dtArr[1] + sep + dtArr[2] + sep + dtArr[0];
  }
}

export function currencyFormat(currValue, numDecimals, noCurrSymbol) {
  // FORMAT CURRENCY VALUES FOR DISPLAY
  let cVal =
    currValue === undefined || isNaN(currValue) || String(currValue).trim() === "" ? "0" : currValue;
  let cSymbol = noCurrSymbol ? "" : i18n.t("currency") + " ";
  let numDeci =
    numDecimals === 0 ? 0 : numDecimals && String(numDecimals).length ? numDecimals : NUMBER_OF_DECIMALS;
  return cSymbol + String(Number(cVal).toFixed(numDeci).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
}


export const commonTableBody = (results, body) => {
  if (results?.length > 0) {
    let rowArray = [];
    let newData = Array(results.length).fill(body);
    newData.forEach((row, rowIndex) => {
      let anArray = [];
      let hideCheckBox = "false"; // USED FOR INTERFACE SEARCH PAGE
      row.forEach((col) => {
        hideCheckBox = "false";
        if (
          col.name === "checkbox" &&
          col.checkStatusSuccessToHide === "yes" &&
          results[rowIndex]["status"].toLowerCase() === "success"
        ) {
          hideCheckBox = "true";
        }

        if (col.type === "checkbox" && col?.checkStatusSuccessToHide === "yes") {
          // USED FOR INTERFACE SEARCH PAGE
          let colObject = {};
          colObject = {
            ...col,
            value: results[rowIndex][col.name],
            id: results[rowIndex].id,
            hideCheckbox: hideCheckBox,
          };
          anArray.push(colObject);
        } else if (col.type === "link") {
          anArray.push({ ...col, id: results[rowIndex].id });
        } else if (col.type === "object") {
          let colObject = {};
          colObject = {
            ...col,
            value: results[rowIndex][col.obj_name]?.name,
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "boolean") {
          let colObject = {};
          colObject = {
            ...col,
            value: results[rowIndex][col.name],
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "responsiblePayer") {
          let colObject = {};
          if (results[rowIndex].payer_types.type === "patient") {
            colObject = {
              ...col,
              directLink: false,
              directLinkPath: ROUTE_PATIENTS_LIST,
              directLinkState: {
                selectedID: Number(results[rowIndex].payer_types.pk),
              },
              value: results[rowIndex][col.name],
              id: results[rowIndex].id,
            };
          } else if (results[rowIndex].payer_types.type === "insurance") {
            colObject = {
              ...col,
              directLink: true,
              directLinkPath: ROUTE_INSURANCE_COMPANIES_EDIT,
              directLinkState: {
                selectedID: Number(results[rowIndex].payer_types.pk),
              },
              value: results[rowIndex][col.name],
              id: results[rowIndex].id,
            };
          }
          else if (results[rowIndex].payer_types.type === "paid") {
            colObject = {
              ...col,
              value: results[rowIndex][col.name],
              id: results[rowIndex].id,
            };
          }
          anArray.push(colObject);
        } else if (col.type === "warning") {
          let colObject = {};
          colObject = {
            ...col,
            is_warnings: results[rowIndex][col.name],
            value: [],
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "ERAPostingAppliedStatus") {
          let colObject = {};
          colObject = {
            ...col,
            value:
              results[rowIndex][col.name] === 1
                ? i18n.t("payments.eraPage.reviewEra")
                : results[rowIndex][col.name] === 2
                  ? i18n.t("payments.eraPage.applied") 
                  : results[rowIndex][col.name] === 3
                    ? i18n.t("payments.eraPage.partiallyApplied")
                : "",
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "exportOptionsHCFA") {
          let colObject = {};
          colObject = {
            ...col,
            value: [
              {
                toolTipLabel: i18n.t("commons.exportWithHCFA"),
                secondParameter: results[rowIndex].id,
                thirdParameter: "yes",
                fileIconClass: "las la-file-pdf file-icons-red",
              },
              {
                toolTipLabel: i18n.t("commons.exportWithoutHCFA"),
                secondParameter: results[rowIndex].id,
                thirdParameter: "no",
                fileIconClass: "las la-file-pdf file-icons",
              },
            ],
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "exportOptionsERAPosting") {
          let colObject = {};
          colObject = {
            ...col,
            value: [
              {
                toolTipLabel: i18n.t("commons.downloadERAFile"),
                secondParameter: results[rowIndex].id,
                thirdParameter: "",
                hrefLink: '',
                downloadFileName: '',
                fileIconClass: "las la-file-alt file-icons",
              },
              {
                toolTipLabel: i18n.t("commons.exportXLS"),
                secondParameter: results[rowIndex].id,
                thirdParameter: "xls",
                hrefLink: "",
                downloadFileName: "",
                fileIconClass: "las la-file-excel file-icons",
              },
              {
                toolTipLabel: i18n.t("commons.exportPDF"),
                secondParameter: results[rowIndex].id,
                thirdParameter: "pdf",
                hrefLink: "",
                downloadFileName: "",
                fileIconClass: "las la-file-pdf file-icons-red",
              },
            ],
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        } else if (col.type === "expand") {
          if (col.expandData.dataFrom === "interface") {
            let colObject = {};
            colObject = {
              ...col,
              id: results[rowIndex].id,
              expandData: {
                dataFrom: "interface",
                dataObject: results[rowIndex].status_message,
              },
            };
            anArray.push(colObject);
          }
        } else if (col.type === "download") {
          let colObject = {};
          colObject = {
            ...col,
            value: i18n.t("csvTemplates.download"),
            id: results[rowIndex].id,
            directLinkPath: results[rowIndex].url,
          };
          anArray.push(colObject);
        } else {
          let colObject = {};
          colObject = {
            ...col,
            value: results[rowIndex][col.name],
            id: results[rowIndex].id,
          };
          anArray.push(colObject);
        }
      });
      rowArray.push(anArray);
    });
    return rowArray;
  } else {
    let tempObj = body;
    tempObj[0].id = "";
    return [tempObj];
  }
};

export const humanizeText = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const checkPermission = (...permissionRequests) => {
  try {
    // Retrieve the data from local storage and parse it
    const storedPermissions = JSON.parse(getStorage('permissionCodeSet'));

    if (permissionRequests?.length) {
      for (const permission_key of permissionRequests) {
        if (storedPermissions[permission_key]) {
          return true; // Permission found, break the loop and return true
        }
      }
    } else if(permissionRequests?.length==0) {
      return true;
    }else{
      return false; // None of the permissions were found
    }
  } catch (error) {
    // Handle JSON parsing errors here
    return false;
  }
};


export const convertToDecimal=(num,decPoint)=>{
  //convert number to decimal points
 return Number(num).toFixed(decPoint)
}

export const formatDateUS = (date) => {
  if (!moment(date).isValid()) {
    return "";
  }
  return moment(date).format('YYYY-MM-DD');
}


/**
* Extracts and compiles a map of permission code names from the login response.
* @param {Permission[]} permissionsList - The list of permissions to process.
* @returns {PermissionMap} - A map of permission code names to boolean values.
*/
export function extractPermissions(practiceRolePermissionsList, userRolePermissionsList) {
  const practicePermissionCodes = {};

  practiceRolePermissionsList.forEach((item) => {
    practicePermissionCodes[item?.permission_code_name] = true;

    if (item?.sub_module?.length) {
      item.sub_module.forEach((subItem) => {
        practicePermissionCodes[subItem?.permission_code_name] = true;

        for (const key in subItem.sub_module_permission) {
          if (subItem.sub_module_permission[key]?.length) {
            subItem.sub_module_permission[key]?.forEach((nestedPermission) => {
              practicePermissionCodes[nestedPermission?.codename] = true;
            });
          }
        }
      });
    }
  });

  const userPermissionCodes = (userRolePermissionsList || []).reduce((acc, item) => {
    if (item?.codename) {
      acc[item.codename] = true;
    }
    return acc;
  }, {});

  return { ...practicePermissionCodes, ...userPermissionCodes };
}


const colors = [
  "#F44336", // Red
  "#E91E63", // Pink
  "#9C27B0", // Purple
  "#673AB7", // Deep Purple
  "#3F51B5", // Indigo
  "#2196F3", // Blue
  "#03A9F4", // Light Blue
  "#00BCD4", // Cyan
  "#009688", // Teal
  "#4CAF50", // Green
  "#8BC34A", // Light Green
  "#CDDC39", // Lime
  "#FFEB3B", // Yellow
  "#FFC107", // Amber
  "#FF9800", // Orange
  "#FF5722", // Deep Orange
];

export const stringToColor = (inputString) => {
  // Convert the input string to a numerical value
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Get the index of the color to use
  const index = Math.abs(hash) % colors.length;

  // Return the color
  return colors[index];
};



/**
 * Gets the path to route the user on login to practice.
 * By default system will not allow user to route to dashboard, instead this function will check next available parent menu or
 * its sub-menu and routes to that path
 * @param {PracticeModuleMenuSet} practiceModuleMenuSet - The list of practice module menus.
 * @returns path to route the user on login
 */
export const getPathToRouteOnLoginToPractice = (practiceModuleMenuSet) => {
  // Check if the user only has access to the Dashboard
  if (practiceModuleMenuSet.length === 1 && practiceModuleMenuSet[0]?.route === ROUTE_DASHBOARD) {
    return (practiceModuleMenuSet[0]?.route);
  } else {
    // If the user has access to other modules
    for (const module of practiceModuleMenuSet) {
      // If the module has a sub-menu, route to the first sub-menu item
      if (module.sub_menu.length > 0) {
        const subMenuRoute = module.sub_menu.find((subMenu) => subMenu.route !== '#')?.route;
        if (subMenuRoute) {
          return (subMenuRoute);
        }
      } else if (module.route !== '#' && module.route !== '' && module.route !== ROUTE_DASHBOARD) {
        // If the module doesn't have a sub-menu, route to the module itself
        return (module.route);
      } else if (module.route === ROUTE_DASHBOARD && practiceModuleMenuSet.length === 1) {
        return (ROUTE_DASHBOARD);
      }
    }
  }
}


/**
 * Gets the path to route the user on login as staff_user or super_admin and admin does not have any default practice.
 * @param {adminModuleMenuSet} adminModuleMenuSet - The list of admin module menus.
 * @returns path to route the user on login
 */
export const getPathToRouteOnLoginToAdminModule = (adminModuleMenuSet) => {
  // Route the user dynamically to the first page they have access to
  if (adminModuleMenuSet[0]?.route === ROUTE_DASHBOARD || (adminModuleMenuSet[0]?.route !== '#' && adminModuleMenuSet[0]?.route !== '')) {
    return (adminModuleMenuSet[0]?.route);
  } else {
    // If the first module has submodules then find one from the sub module list and route to that submodule page
    const route = adminModuleMenuSet[0]?.sub_menu.find((subMenu) => {
      return subMenu.route !== '#';
    })?.route;

    if (route) {
      return (route);
    }
  }
}


/**
 * Opens a URL in a centered popup window with customizable dimensions.
 *
 * @param {string} url - The URL to open in the popup window.
 * @param {string} [windowName='popupWindow'] - The name of the popup window. If a window with the same name already exists, it will be reused.
 * @param {number} [widthFactor=0.8] - The factor by which to scale the popup window's width relative to the parent window's width (0 < widthFactor <= 1).
 * @param {number} [heightFactor=0.8] - The factor by which to scale the popup window's height relative to the parent window's height (0 < heightFactor <= 1).
 * @param {onClose} callback function to execute on close
 * @returns {Window|null} A reference to the newly opened window, or null if the operation was unsuccessful.
 *
 * @example
 * const claimUrl = `${window.location.origin}/searchcLAIMS`;
 * openCenteredPopup(claimUrl, 'claimPopup');
 */
export function openCenteredPopupWindow(url, windowName = 'popupWindow', widthFactor = 0.8, heightFactor = 0.8, onClose) {
  // Ensure the widthFactor and heightFactor are within acceptable bounds
  if (widthFactor <= 0 || widthFactor > 1) {
    widthFactor = 0.8; // Default to 80% if out of bounds
  }
  if (heightFactor <= 0 || heightFactor > 1) {
    heightFactor = 0.8; // Default to 80% if out of bounds
  }

  // Fallback values for dimensions
  const screenWidth = window.screen.availWidth;
  const screenHeight = window.screen.availHeight;

  // Calculate the popup dimensions
  const popupWidth = Math.floor(screenWidth * widthFactor);
  const popupHeight = Math.floor(screenHeight * heightFactor);

  // Calculate the position to center the popup
  const popupLeft = Math.floor((screenWidth - popupWidth) / 2);
  const popupTop = Math.floor((screenHeight - popupHeight) / 2);

  // Open the popup window
  const popup = window.open(
    url,
    windowName,
    `width=${popupWidth},height=${popupHeight},top=${popupTop},left=${popupLeft},resizable,scrollbars`
  );

  // Set an interval to periodically check if the popup is closed
  if (onClose) {

    // Function to check if the popup window is closed
    const checkPopupClosed = () => {
      if (popup && popup.closed) {
        clearInterval(checkInterval); // Stop the interval once the popup is closed
        if (typeof onClose === 'function') {
          onClose(); // Call the provided callback function when the popup is closed
        }
      }
    };

    const checkInterval = setInterval(checkPopupClosed, 500);
  }

  return popup;
}

/**
 * function to clear indexedDB Data
 */
export async function clearIndexedDb() {
  try {
    await clearAllIndexedDbData();
  } catch (error) {
    console.error('Error clearing IndexedDB:', error);
  }
}