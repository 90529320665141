import i18n from "../../../../utilities/i18n";

const TableHeadData = [
    {
        "name": i18n.t('statementManagement.patientId'),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t('commons.patient'),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t('statementManagement.releaseTracker.claims'),
        "type": "string",
        "width": "medium",
        "sort": false,
    }
];

export const QueuedItemsTableBodyData = [
    [
        {
            "id": "",
            "name": "custom_patient_id",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_name",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "claims",
            "type": "multiple_chip",
            "align": "left",
            "width": "medium",
        }
    ]
];

export const QueuedItemsTableConfig = {
    "name": "statement-management-table",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": QueuedItemsTableBodyData
};