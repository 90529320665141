import React from 'react';  
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as staticConfigs from '../utilities/staticConfigs';
import { getStorage } from '../utilities/browserStorage';
import checkPermissionMiddleware from './permissionCheckMiddleware';
import NoPermission from "../components/NoPermissionPage/NoPermission";
import { clearIndexedDb } from '../utilities/commonUtilities';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let location   = rest.location.pathname;
    let loginRoute = staticConfigs.LOGIN_ROUTE;
    if (getStorage('passwordExpired')){
        return (
            <Route {...rest} render={() => (
                <Redirect to={{
                    pathname: staticConfigs.ROUTE_FORCE_RESET_PASSWORD,
                    state: { userID: getStorage('userID') }
                }}
                />
            )}/>
        )
    }

    let userGroupData = JSON.parse(getStorage('userData') || '{}');
    let userGroupToken = null;

    if (userGroupData) {
        userGroupToken = userGroupData.accessToken ?? null;
    }
    else {
        // Clear IndexedDB
        clearIndexedDb();
        // Reset all Redux states
        dispatch({ type: 'chargesDebit/onResetData' });
        dispatch({ type: 'paymentAdjustment/onResetData' });
        dispatch({ type: 'openArReport/onResetData' });
        dispatch({ type: 'reportFilters/reset' });
        dispatch({ type: 'statementMgmnt/resetData' });
        dispatch({ type: 'patientInfo/setInitialState' });
        dispatch({ type: 'claimManagement/resetClaimtoInitalState' });
        dispatch({ type: 'claimSaveManagement/setClaimSavetoInitialState' });
        dispatch({ type: 'arClaims/ResetData' });
        dispatch({ type: 'ClaimFilter/reset' });
        dispatch({ type: 'batchEligibility/onResetData' });
        dispatch({ type: 'chatbox/reset' });
        dispatch({ type: 'commonStates/reset' });
        dispatch({ type: 'claimManagement/resetToInitial' });
        dispatch({ type: 'DropDownOptionSlice/resetToInitial' });
        // Redirect to login
        history.push(loginRoute);
    }

    // Rout to NoPermission component when user is logged in but permission is denied
    if (userGroupToken !== null && !checkPermissionMiddleware(location)) {
        return (
            <Route {...rest} render={() => (
                <NoPermission />
            )} />
        )
    }

    // render Component when userGroupToken is not null and checkPermissionMiddleware(location) is true
    if (userGroupToken !== null && checkPermissionMiddleware(location)) {
        return (
            <Route {...rest} render={(props) => (
                <Component {...props} />
            )} />
        )
    }

    // render Redirect to loginRoute when userGroupToken is null
    return (        
        <Route {...rest} render={(props) => (
            <Redirect to={{
                pathname: loginRoute,
                state: { from: props.location }
            }}
            />
        )}/>
    )
}
export default PrivateRoute;

