import api from '../../../service/api'

const ListPracticeTypes = async () => {
	let path = 'super-admin/practice-type/?page=0';
	return api.get(path);
}

const callNPISearchAPI = async (searchObj) => {
	let path = 'super-admin/api-call/';
	let urlString = 'https://npiregistry.cms.hhs.gov/api/?version=2.1&limit=200';
	if (searchObj.orgName && searchObj.orgName.trim().length > 0) urlString += '&organization_name=' + searchObj.orgName
	if (searchObj.city && searchObj.city.trim().length > 0) urlString += '&city=' + searchObj.city
	if (searchObj.state && searchObj.state.trim().length > 0) urlString += '&state=' + searchObj.state
	let urlData = { "url": urlString };
	return api.post(path, urlData);
}

const ListPrcatices = async (pageSize, page, practicePK, status) => {
	let path = 'practice/practice/?page_size=' + pageSize + '&page=' + page;
	if (practicePK)
		path += '&practice_pk=' + practicePK;
	if (status)
		path += '&status=' + status;
		return api.get(path);
}


const AddPractice = async (data) => {
	let path = 'practice/practice/'
	return api.post(path, data);
}

const UpdatePractice = async (practiceID, data) => {
	let path = 'practice/practice/' + practiceID
	return api.put(path, data);
}

const GetPractice = async (practicePK) => {
	let path = 'practice/practice/' + practicePK;
	return api.get(path);
}

const ListPracticeLocations = async (pageSize, page, practicePK, no_default, status) => {
	let path = 'practice/practice-location/?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page + '&list=true';
	if (no_default)
		path += '&no_default=' + true;
	if (status)
		path += '&status=' + status;
		return api.get(path);
}

const SavePracticeLocation = async (data) => {
	let path = 'practice/practice-location/';
	return api.post(path, data);
}

const GetPracticeLocationData = async (locationPK) => {
	let path = 'practice/practice-location/' + locationPK;
	return api.get(path);
}

const UpdatePracticeLocation = async (locationPK, data) => {
	let path = 'practice/practice-location/' + locationPK;
	return api.put(path, data);
}

const Taxonomy = async (pageSize, page) => {
	let path = 'super-admin/taxonomy/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const exportPractices = async (type, practicePK) => {
	const path = 'practice/practice/?practice_pk=' + practicePK + '&export=true&export_type=' + type;
	let result = null;
	if (type === 'xls')
		result = api.getXLS(path);
	else
		result = api.get(path);
	return result;
}

const GetPracticeMenuAndPermissionsOnPracticeSwitch = async (practice) => {
	const path = `user/practice-permissions/?practice_pk=${practice}`
	return await api.get(path)
};


const GetPracticeStatementConfig = async (practicePk) => {
	return await api.get(`practice/practice-statement-config/${practicePk}`);
}

const UpdatePracticeStatementConfig = async (practicePk, data) => {
	return await api.put(`practice/practice-statement-config/${practicePk}`, data);
}

export default {
	exportPractices,
	ListPracticeTypes, ListPracticeLocations, callNPISearchAPI, AddPractice, UpdatePractice, ListPrcatices, GetPractice,
	SavePracticeLocation, GetPracticeLocationData, UpdatePracticeLocation, Taxonomy, GetPracticeMenuAndPermissionsOnPracticeSwitch,
	GetPracticeStatementConfig, UpdatePracticeStatementConfig
}