// Redux Utils
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Api services
import service from "../Services";
import patientServices from "../../patients/service";
import claimService from '../../../ClaimsModule/SearchClaims/service';

// Util functions
import { getStorage } from "../../../../utilities/browserStorage"

// Constants
import { DEFAULT_PAGING_SIZE, PATIENT_STATEMENT_STATUSES, PAGING_END_INDEX, PATIENT_STATEMENT_VENDORS, TAB1 } from "../../../../utilities/staticConfigs";
import { RESPONSIBILITY_TYPES } from "../../../../utilities/dictionaryConstants";

// External Libraries
import fileDownload from 'js-file-download';



export const initialStatementReleaseTrackerState = {
    filters: {
        status: "",
        release_date_from: "",
        release_date_to: "",
        batch_id: "",
        patient_selected: [],
        patient_pk: "",
        claim_pk: "",
        patientAdvSearchData: [],
        selected_claim: [],
        statement_vendor: PATIENT_STATEMENT_VENDORS.find(item => item.name === "DMA")?.id,
    },
    tableData: [],
    pagination: {
        startIndex: 0,
        endIndex: PAGING_END_INDEX,
        page_size: DEFAULT_PAGING_SIZE,
        page: 1,
        total_pages: 0,
    },
    storedFiltersForPagination: {},
    apiState: {
        isLoading: false,
        isError: false,
        message: ""
    }
};


export const initialStateStatementManagementTab1 = {
    filters: {
        statement_vendor: PATIENT_STATEMENT_VENDORS[0]?.id,
        patient_selected: [],
        patient_pk: "",
        patientAdvSearchData: [],
        dos_from: null,
        dos_to: null,
        entered_from: null,
        entered_to: null,
        insurances: [],
        claim_balance_from: "",
        claim_balance_to: "",
        providers: [],
        facilities: [],
        facility_types: [],
        bill_pr_codes: [],
        claim_status: []
    },
    tableData: [],
    queuedItems: [],
    pagination: {
        startIndex: 0,
        endIndex: PAGING_END_INDEX,
        page_size: DEFAULT_PAGING_SIZE,
        page: 1,
        total_pages: 0,
    },
    storedFiltersForPagination: {},
    selectedEntries: [],
    apiState: {
        isLoading: false,
        isError: false,
        message: ""
    },
    showQueuedItemsModal: false
}


const initialState = {
    commons: {
        activeTab: TAB1,
        openedPatientTabs: [],
        previewAllTabItems: [],
        previewAllQuery: '',
        statementQueries: ''
    },
    statementManagementTabData: initialStateStatementManagementTab1,
    statementReleaseTrackerTabData: initialStatementReleaseTrackerState,
    dropDownListData: {
        isLoading: false,
        isErrorGettingDropdownData: false,
        isFilterDropDownDataReceived: false,
        InsuranceNameList: [],
        ProviderList: [],
        FacilityList: [],
        BillPRList: [],
        ClaimStatusList: [],
    },
    loading: false,
    errorState:'',
    resetFlag:false,
};

/*
 * Function to get call the api for all the dropdown list data 
 */
export const getDropDownListData = createAsyncThunk(
    "statementManagment/getDropDownListData",
    async (_, thunkAPI) => { 
        const pageSize = 0;
        const page = 0;
        const end_index = 10;
        const pageNum = 1
        const practicePK = getStorage('practice');

        try {
            const [
                InsuranceNameList,
                ProviderList,
                FacilityList,
                BillPRList,
                ClaimStatusList,
            ] = await Promise.all([
                patientServices.ListInsuranceNames(pageSize, page, practicePK),
                patientServices.ListRenderingProviders(end_index, pageNum, practicePK),
                claimService.ServiceLocationLists(practicePK),
                patientServices.GetBillByPRCode(pageSize, page, practicePK, 'PR'),
                claimService.ListClaimStatus(pageSize, page, practicePK),
            ]);

            return {
                InsuranceNameList: InsuranceNameList.data,
                ProviderList: ProviderList.data.results,
                FacilityList: FacilityList.data.map(item => ({ ...item, id: item.Id + '||' + item.location_type })),
                BillPRList: BillPRList.data.results,
                ClaimStatusList: ClaimStatusList.data.filter(item =>
                    item.priorities?.includes(
                        RESPONSIBILITY_TYPES.find(priority => priority.name === "Patient")?.id
                    )
                )
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' });
        }
    }
);


export const getStatementReleaseTrackerData = createAsyncThunk(
    "statementManagement/getStatementReleaseTrackerData",
    async ({ filters, practicePK, pagination, is_export = false }, thunkAPI) => {
        try {
            const response = await service.getStatementReleaseTrackerData({
                ...filters,
                practice_pk: practicePK,
                page: pagination.page,
                page_size: pagination.page_size,
                is_export
            });

            if (response.status === 200) {
                if (is_export) {
                    fileDownload(response.data, response.headers["content-disposition"]);
                    return {
                        is_export
                    };
                }
                return {
                    data: response.data,
                    pagination
                };
            }

            const err_message = response.error_data?.error_message ?? null;

            return thunkAPI.rejectWithValue(err_message || "Failed to fetch statement release tracker data.");
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const getStatementManagementTableData = createAsyncThunk(
    "statementManagement/getStatementManagementTableData",
    async ({ filters, practicePk, pagination }, thunkAPI) => {
        try {
            const response = await service.getStatementManagementTableData({
                ...filters,
                practice_pk: practicePk,
                page: pagination.page,
                page_size: pagination.page_size,
            });

            if (response.status === 200) {
                return {
                    data: response.data,
                    pagination
                };
            }

            const err_message = response.error_data?.error_message ?? null;

            return thunkAPI.rejectWithValue(err_message || "Failed to fetch statement management table data.");
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const queueStatementsForAutomatedRelease = createAsyncThunk(
    "statementManagement/queueStatementsForAutomatedRelease",
    async (selectedEntries, { rejectWithValue }) => {
        try {
            const response = await service.queueStatementsForAutomatedRelease(selectedEntries);

            if (response.status === 200) {
                return response.data;    
            }

            return rejectWithValue("An error occurred while queuing the items for statement release.");
        } catch (error) {
            if (!error.response) {
                return rejectWithValue({ message: 'Network error. Please check your connection.' });
            }

            return rejectWithValue({
                message: error.response.data.message || 'An unexpected error occurred',
                status: error.response.status
            });
        }
    }
);



/**
 * Helper function to handle error states in statement management
 * @param {Object} state - Current Redux state
 * @param {string} errorType - Type of error (NO_DATA or DEFAULT)
 * @param {string} errorMessage - Custom error message
 */
const handleStatementTableError = (state, errorType = 'NO_DATA', errorMessage = '') => {
    // Reset table data
    state.statementManagementTabData.tableData = [];

    // Reset pagination
    state.statementManagementTabData.pagination = {
        ...state.statementManagementTabData.pagination,
        total_pages: 0,
        page: 1,
        startIndex: initialStatementReleaseTrackerState.pagination.startIndex,
        endIndex: initialStatementReleaseTrackerState.pagination.startIndex
    };

    // Set API state based on error type
    const isNoDataError = errorMessage === "no_procedures_found_for_the_specified_practice_and_patient." ||
        errorType === 'NO_DATA';

    state.statementManagementTabData.apiState = {
        isLoading: false,
        isError: true,
        message: isNoDataError
            ? "No data found for the given query."
            : "An error occurred while processing your request. Please verify your selected filters and try again. If the issue persists, contact support.",
        notifyPrimaryText: isNoDataError ? "No data" : "Error",
    };
};



/**
 * statement management slice
 */
export const statementManagementSlice = createSlice({
    name: 'statementMgmnt',
    initialState,
    reducers: {
        // Accepts the name of the state, the field, and the value to handle value change in tickets add or edit modal
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
        resetData: () => initialState,
        resetStatementReleaseTrackerData: (state) => {
            state.statementReleaseTrackerTabData = initialStatementReleaseTrackerState;
        },
    },


    extraReducers: (builder) => {
        builder
            .addCase(getDropDownListData.pending, (state) => {
                state.dropDownListData.isLoading = true;
                state.dropDownListData.isErrorGettingDropdownData = false;
            })
            .addCase(getDropDownListData.fulfilled, (state, action) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = false;
                state.dropDownListData.isFilterDropDownDataReceived = true;

                Object.keys(action.payload).forEach(key => {
                    state.dropDownListData[key] = action.payload[key];
                });
            })
            .addCase(getDropDownListData.rejected, (state) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = true;
            });
        builder
            .addCase(getStatementReleaseTrackerData.pending, (state) => {
                state.statementReleaseTrackerTabData.apiState.isLoading = true;
            })
            .addCase(getStatementReleaseTrackerData.fulfilled, (state, action) => {
                const { results, links, count, page_size } = action.payload.data || {};
                const { startIndex, endIndex } = action.payload.pagination || {};

                if (action.payload.is_export) {
                    state.statementReleaseTrackerTabData.apiState = initialStatementReleaseTrackerState.apiState
                    return
                }

                if (Array.isArray(results) && results.length) {
                    const processedTableData = results.map((item) => {
                        const patientName = item.patient?.display_name || "Unknown Patient"; // Fallback for missing patient name
                        const claims = item.claims.map((claim) => ({
                            ...claim,
                            label: claim.custom_claim_id,
                        }));

                        const status = PATIENT_STATEMENT_STATUSES.find(status => status.id === item.status)?.name || item.status;

                        return {
                            ...item,
                            patient_name: patientName,
                            claims,
                            status,
                        };
                    });

                    state.statementReleaseTrackerTabData.tableData = processedTableData;
                    state.statementReleaseTrackerTabData.pagination = {
                        ...state.statementReleaseTrackerTabData.pagination,
                        total_pages: Math.ceil(count / page_size) ?? 0,
                        page: links.current_page,
                        startIndex: startIndex ?? state.statementReleaseTrackerTabData.pagination.startIndex,
                        endIndex: endIndex ?? state.statementReleaseTrackerTabData.pagination.startIndex
                    }

                    state.statementReleaseTrackerTabData.apiState = {
                        isLoading: false,
                        isError: false,
                        message: ""
                    }
                } else {
                    // Handle case where results are not an array or are empty
                    state.statementReleaseTrackerTabData.tableData = [];
                    state.statementReleaseTrackerTabData.pagination = {
                        ...state.statementReleaseTrackerTabData.pagination,
                        total_pages: 0,
                        page: 1,
                        startIndex: initialStatementReleaseTrackerState.pagination.startIndex,
                        endIndex: initialStatementReleaseTrackerState.pagination.startIndex
                    };

                    state.statementReleaseTrackerTabData.apiState = {
                        isLoading: false,
                        isError: true,
                        message: "No records found. Please adjust your filters or broaden your search."
                    }
                }
            })
            .addCase(getStatementReleaseTrackerData.rejected, (state, action) => {
                state.errorState = action.payload;
                state.statementReleaseTrackerTabData.tableData = [];
                state.statementReleaseTrackerTabData.pagination = {
                    ...state.statementReleaseTrackerTabData.pagination,
                    total_pages: 0,
                    page: 1,
                    startIndex: initialStatementReleaseTrackerState.pagination.startIndex,
                    endIndex: initialStatementReleaseTrackerState.pagination.startIndex
                };

                state.statementReleaseTrackerTabData.apiState = {
                    isLoading: false,
                    isError: true,
                    message: "An error occurred while processing your request. Please verify your selected filters and try again. If the issue persists, contact support."
                }
            });

        builder
            .addCase(getStatementManagementTableData.pending, (state) => {
                state.statementManagementTabData.apiState.isLoading = true;
            })
            .addCase(getStatementManagementTableData.fulfilled, (state, action) => {
                const { results, links, count, page_size } = action.payload.data || {};
                const { startIndex, endIndex } = action.payload.pagination || {};

                if (Array.isArray(results) && results.length) {
                    const processedTableData = results.map((item) => {
                        const claims = item.claims.map((claim) => ({
                            ...claim,
                            id: claim.pk,
                            label: claim.custom_claim_id,
                        }));

                        const isPatientSelected = state.statementManagementTabData.selectedEntries.some(entry => entry == item.patient_pk);

                        return {
                            id: item.patient_pk,
                            checked: isPatientSelected,
                            ...item,
                            claims
                        };
                    });

                    state.statementManagementTabData.tableData = processedTableData;
                    state.statementManagementTabData.pagination = {
                        ...state.statementManagementTabData.pagination,
                        total_pages: Math.ceil(count / page_size) ?? 0,
                        page: links.current_page,
                        startIndex: startIndex ?? state.statementManagementTabData.pagination.startIndex,
                        endIndex: endIndex ?? state.statementManagementTabData.pagination.startIndex
                    }

                    state.statementManagementTabData.apiState = {
                        isLoading: false,
                        isError: false,
                        message: ""
                    }
                } else {
                    handleStatementTableError(state, 'NO_DATA');
                }
            })
            .addCase(getStatementManagementTableData.rejected, (state, action) => {
                handleStatementTableError(
                    state,
                    action.payload === "no_procedures_found_for_the_specified_practice_and_patient."
                        ? 'NO_DATA'
                        : 'DEFAULT',
                    action.payload
                );
            });

        builder
            .addCase(queueStatementsForAutomatedRelease.pending, (state) => {
                state.statementManagementTabData.apiState.isLoading = true;
            })
            .addCase(queueStatementsForAutomatedRelease.fulfilled, (state, action) => {
                const queuedItems = action.payload;

                if (Array.isArray(queuedItems.updated_patients) && queuedItems.updated_patients?.length) {
                    const updatedPatients = queuedItems.updated_patients.map((patient) => ({
                        ...patient,
                        claims: patient.claims.map(claim => ({
                            ...claim,
                            label: claim.custom_claim_id
                        }))
                    }));

                    queuedItems.updated_patients = updatedPatients;
                }

                if (queuedItems) {
                    state.statementManagementTabData.queuedItems = queuedItems;
                    state.statementManagementTabData.showQueuedItemsModal = true;
                }

                state.statementManagementTabData.apiState = {
                    isSuccess: true,
                    isLoading: false,
                    isError: false,
                    message: "Successfully added selected items to statement release queue."
                };
            })
            .addCase(queueStatementsForAutomatedRelease.rejected, (state, action) => {
                state.statementManagementTabData.apiState = {
                    isLoading: false,
                    isError: true,
                    message: action.payload?.message || "An error occurred while queueing statements for automated release."
                };
            });
    }
})

export const {
    resetData,
    updateFieldValues,
    resetStatementReleaseTrackerData,
} = statementManagementSlice.actions

export default statementManagementSlice.reducer;
